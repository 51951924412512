namespace GSapp.controllers {
  'use strict';

  export class MapController extends controllers.BaseController {
    busy: boolean = false;
    loading: boolean = false;
    siteList: any = {};
    summarydata: any = {};
    mapEvents = {
      map: {
        // enable: ['zoomend', 'dragend', 'overlayadd', 'overlayremove', 'baselayerchange'],
        enable: ['zoomend', 'dragend', 'overlayadd', 'overlayremove'],
        logic: 'emit'
      }
    };
    allmarkers: any = {};
    markers: any = {};
    lat: any = [];
    lng: any = [];
    visbleMarkers: any = [];
    offMapMarkers: any = [];
    visibleSites: any = [];
    visibleLayers: any = {};
    stormLayers: any = {};
    showMap: boolean = true;
    windowWidth: any = 0;
    isMobile: boolean = false;
    layers: any = {
      baselayers: {
        googleHybrid: {
          name: "Hybrid - Google",
          url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
          type: 'xyz',
          layerOptions: {
            attribution: `<span jstcache="167" class=" ZiKfbc tUdUte LFaNsb" style="" id="LFaNsb" jsan="7.ZiKfbc,7.tUdUte,7.LFaNsb,0.id">Imagery ©2022 CNES / Airbus, Maxar Technologies, U.S. Geological Survey, USDA/FPAC/GEO, Map data ©2022 Google</span>`
          }
        },
        googleStreets: {
          name: "Streets - Google",
          url: 'https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}',
          type: 'xyz',
          layerOptions: {
            attribution: `<span id="LFaNsb" jstcache="167" class="ZiKfbc tUdUte LFaNsb" jsan="7.ZiKfbc,7.tUdUte,7.LFaNsb,0.id">Map data ©2022 Google</span>`
          }
        },
        googleSat: {
          name: "Satellite - Google",
          url: 'https://www.google.com/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
          type: 'xyz',
          layerOptions: {
            attribution: `<span jstcache="167" class=" ZiKfbc tUdUte LFaNsb" style="" id="LFaNsb" jsan="7.ZiKfbc,7.tUdUte,7.LFaNsb,0.id">Imagery ©2022 CNES / Airbus, Maxar Technologies, U.S. Geological Survey, USDA/FPAC/GEO, Map data ©2022 Google</span>`
          }
        },
        osm: {
          name: 'Streets - OpenStreetMap',
          url: 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
          type: 'xyz',
          layerOptions: {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          }
        }
      },
      overlays: {
      }
    };
    maxbounds: any = {};
    usaBounds = L.latLngBounds(
      L.latLng(5.499550, -167.276413), //Southwest
      L.latLng(83.162102, -52.233040)  //Northeast
    );

    divIconMarkerClass = {
      lat: null,
      lng: null,
      layer: "Sites",
      icon: {
        type: 'div',
        iconSize: [48, 48],
        html: '',
        popupAnchor: [0, -24]
      },
      popupOptions: { closeButton: false },
      message: "Marker Template"
    }

    overviewChartData: any = {};
    overviewChartDataFull: any = [];

    emChartColors: any = [
      '#FFD43A',
      '#1E752B',
      '#34AFF7',
      '#008000',
      '#34AFF7',
      '#249FE7',
      '#148FD7',
      '#147FC7',
      '#0D3D14',
      '#1e752b',
      '#14571E',
      '#2EDB4B',
      '#A0ABC0'
    ];
    emChartLabels: any = ['Utility',
      'Asset',
      'Renewable',
      'Non Renewable',
      'BESS',
      'Steam Turbine',
      'Solar',
      'Wind',
      'NG Gen',
      'Diesel Gen',
      'Gas Turbine',
      'Fuel Cell',
      'Other'
    ];
    emChartDatasets: any = [];
    emChartSetup: any = {
      type: 'pie',
      plugins: [globalThis.ChartDataLabels],
      data: {
        datasets: []
      },
      datalabels: {
        anchor: 'center',
        backgroundColor: null,
        borderWidth: 0
      },
      options: {
        plugins: {
          legend: {
            display: false,
            position: 'bottom'
          },
          title: {
            display: false,
            text: 'Select a Site',
          },
          datalabels: {
            backgroundColor: function (context) {
              return context.dataset.backgroundColor;
            },
            borderColor: 'white',
            borderRadius: 25,
            borderWidth: 2,
            color: function (context) {
              let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(context.dataset.backgroundColor[context.dataIndex]);
              let uicolors = [parseInt(result[1], 16) / 255, parseInt(result[2], 16) / 255, parseInt(result[3], 16) / 255]
              let c = uicolors.map((col) => {
                if (col <= 0.03928) {
                  return col / 12.92;
                }
                return Math.pow((col + 0.055) / 1.055, 2.4);
              });
              let lum = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
              return (lum > 0.179) ? '#000000' : '#ffffff';
            },
            display: function (context) {
              let dataset = context.dataset;
              let datasetNonZero = context.dataset.data.filter((value) => { return value > 0 })
              let count = datasetNonZero.length;
              let value = dataset.data[context.dataIndex];
              return value > count * 1.5;
            },
            font: {
              weight: 'bold'
            },
            padding: 4,
            formatter: Math.round
          }
        },
        responsive: true,
        animation: false,
        maintainAspectRatio: false
      }
    }

    orChartColors: any = ['#6D7C8F', '#2EDB4B', '#FFD43A'];
    orChartDatasets: any = [];
    orChartSetup: any = {
      type: 'bar',
      data: {
        datasets: []
      },
      options: {
        elements: { point: { pointRadius: 0 } },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            type: 'time',
            display: false,
            time: {
              unit: 'minute', tooltipFormat: "hh:mm Z",
              displayFormats: { minute: "hh:mm Z" }
            },
            title: {
              display: false,
              text: 'Time Stamp'
            },
            ticks: {
              source: 'auto',
              maxRotation: 0,
              autoSkip: true
            }
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom'
          },
          title: {
            display: false,
            text: 'Select a Site',
          }
        },
        responsive: true,
        animation: false,
        maintainAspectRatio: false
      }
    }

    ppChartColors: any = ['#34AFF7', '#2EDB4B', '#FFD43A'];
    ppChartDatasets: any = [];
    ppChartSetup: any = {
      type: 'line',
      data: {
        datasets: []
      },
      options: {
        elements: { point: { pointRadius: 0 } },
        interaction: {
          intersect: false,
          mode: 'index',
        },
        scales: {
          x: {
            type: 'time',
            display: false,
            time: {
              unit: 'minute', tooltipFormat: "hh:mm Z",
              displayFormats: { minute: "hh:mm Z" }
            },
            title: {
              display: false,
              text: 'Time Stamp'
            },
            ticks: {
              source: 'auto',
              maxRotation: 0,
              autoSkip: true
            }
          }
        },
        plugins: {
          legend: {
            display: false,
            position: 'bottom'
          },
          title: {
            display: false,
            text: 'Select a Site',
          }
        },
        responsive: true,
        animation: false,
        maintainAspectRatio: false
      }
    }
    decimation: any = {
      enabled: false,
      algorithm: 'min-max',
      samples: 100
    }
    ppLineChart: Chart = null;
    orLineChart: Chart = null;
    emPieChart: Chart = null;

    static readonly $inject = [
      'SiteResource',
      '$scope',
      'leafletData',
      'leafletMapEvents',
      'leafletBoundsHelpers',
      'SystemResource',
      '$window',
      '$q',
      '$cookies',
      'ReportResource'
    ];

    constructor(
      public SiteResource: interfaces.ISiteResource,
      public $scope: ng.IScope,
      public leafletData: any,
      public leafletMapEvents: any,
      public leafletBoundsHelpers: any,
      public SystemResource: interfaces.ISystemResource,
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource) {
        super(SystemResource, $window, $q, $cookies, ReportResource);

        let self = this;
        let selectPromises = [];
        self.reportWindowSize();
        $window.addEventListener("resize", this.reportWindowSize);

        self.setupPanelEvents();

        self.setupCharts();

        // Quick test for websocket cookies.  Works as of Oct 3, 2024;
        // const socket = new WebSocket("ws://localhost:8080/api/1.3/stream");
        // socket.addEventListener("open", (event) => {
        //   socket.send("Hello Server!");
        // });

        selectPromises.push(self.SystemResource.listmaplayers().$promise);
        selectPromises.push(self.SystemResource.liststormlayers().$promise);

        self.$q.all(selectPromises).then(results => {
          if (results[0]) {
            self.visibleLayers['Sites'] = true;
            self.createMarkerLayer();
            for (let layer of results[0]) {
              if (_.isUndefined(layer.url) || _.isNull(layer.url)) {
                // Disable siet layers for right now.
                // this.addMarkerLayer(layer);
              } else if (layer.url.indexOf('openweathermap.org') > -1) {
                self.visibleLayers[layer.name] = layer.url ? layer.visible : undefined;
                self.addLayerDelay(layer);
              } else {
                self.visibleLayers[layer.name] = layer.url ? layer.visible : undefined;
                self.addLayerNormal(layer);
              }
            }
          }
          self.leafletData.getMap().then(map => {
            map.on('overlayadd', self.onOverlayAdd);
            map.on('overlayremove', self.onOverlayRemove);
            if (results[1]) {
              for (let storm of results[1]) {
                const parser = new DOMParser();
                const kml = parser.parseFromString(storm.layerData, 'text/xml');
                self.stormLayers[storm.code + storm.layerId] = new L['KML'](kml);
                map.addLayer(self.stormLayers[storm.code + storm.layerId]);
              }
            }
          }).finally(() => {
            self.getData(true);
            self.applyFilters(false);
            self.setPanelsOnLoad();
            // refresh site data every 10 seconds
            setInterval(() => { self.getData(false) }, 1000 * 10);
            // refresh chartes every 5 minutes
            setInterval(() => { self.getOverviewChartData() }, 60000 * 5);
            // refresh visible weather layers every hour
            setInterval(() => { self.refreshVisibleLayers() }, 1000 * 60 * 60);
            // add the this.setListeners(); after a few seconds.
            setTimeout(self.setListeners, 2000);
          });
        });
    }

    reportWindowSize = () => {
      let lastWindowWidth = this.windowWidth;
      this.windowWidth = this.$window.innerWidth;
      if (this.windowWidth < 1024) {
        if (lastWindowWidth > this.windowWidth) {
          this.isMobile = true;
        }
      } else if (lastWindowWidth < this.windowWidth) {
        this.isMobile = false;
      }
      this.updateVisible();
    }

    addLayerNormal(layer) {
      this.layers.overlays[layer.id] = {
        name: layer.name,
        url: layer.url ? layer.url : undefined,
        type: layer.type,
        opacity: layer.opacity ? Number(layer.opacity) : 1.0,
        visible: layer.visible ? layer.visible : undefined,
        doRefresh: layer.url ? false : undefined
      }
    }

    createMarkerLayer() {
      let self = this;
      let layer = { id: 'sites', name: 'Sites', type: 'markercluster', opacity: 1.0, visible: true };
      this.layers.overlays[layer.id] = {
        name: layer.name,
        type: layer.type,
        opacity: layer.opacity,
        visible: layer.visible,
        layerOptions: {
          disableClusteringAtZoom: 14,
          spiderfyDistanceMultiplier: 2,
          iconCreateFunction: function (cluster) {
            let markers = cluster.getAllChildMarkers();
            let count = 0;
            let disconnected = 0;
            let inAlarm = 0;
            let inCritAlarm = 0;
            let renewableassetsum = 0;
            let nonrenewableassetsum = 0;
            let running = 0;
            let utilsum = 0;
            let loadsum = 0;
            for (const marker of markers) {
              count++;
              if (marker.options['connected'] == 0) {
                disconnected++;
              }
              if (marker.options['inCritAlarm'] == 1) {
                inCritAlarm++;
              }
              if (marker.options['inAlarm'] == 1) {
                inAlarm++;
              }
              if (marker.options['running'] == 1) {
                running++;
              }
              renewableassetsum = renewableassetsum + (_.isNumber(marker.options['kwData'].rakw) ? marker.options['kwData'].rakw : 0);
              nonrenewableassetsum = nonrenewableassetsum + (_.isNumber(marker.options['kwData'].nrakw) ? marker.options['kwData'].nrakw : 0);
              utilsum = utilsum + (_.isNumber(marker.options['kwData'].utilkw) ? marker.options['kwData'].utilkw : 0);
              loadsum = loadsum + (_.isNumber(marker.options['kwData'].loadkw) ? marker.options['kwData'].loadkw : 0);
            }
            let renewableasset = Math.round(renewableassetsum / loadsum * 100);
            let nonrenewableasset = Math.round(nonrenewableassetsum / loadsum * 100);
            let assets = self.capPercent(renewableasset + nonrenewableasset);
            let util = self.capPercent(Math.round(utilsum / loadsum * 100)) + assets;
            let load = loadsum;
            let donut = `conic-gradient(rgb(91, 196, 57) ` + assets + `%, rgba(0, 0, 0, 0) 0deg), conic-gradient(rgb(11, 171, 251) 0%, rgba(0, 0, 0, 0) 0deg), conic-gradient(rgb(252, 191, 4) ` + util + `%, rgba(0, 0, 0, 0) 0deg`;
            let iconHTML = `
            <div class="w-20 h-20 flex justify-center items-center rounded-full relative shrink-0 ${((disconnected == count) || (running == 0)) ? 'bg-not-running-disconnected' : (inCritAlarm > 0) ? 'bg-running-in-crit-alarm' : (inAlarm > 0) ? 'bg-running-in-alarm' : 'bg-running'}">
            <div class="w-full flex justify-center items-center aspect-square rounded-full overflow-hidden relative">
              <div class="absolute inset-0 rotate-[180deg]" style="mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(99% - 8px), rgb(0, 0, 0) calc(100% - 8px)); 
                background-image: ${donut}">
              </div>
              ${(disconnected == count) ? '<img class="disconnected-icon">' : ''}
              ${(disconnected < count) ? `
                <div class="flex flex-col justify-center items-center">
                  <span class="tracking-tighter leading-none font-medium text-sm text-white">${(Math.round((load) / 10) / 100).toFixed(2)}</span>
                  <span class="tracking-tighter leading-none text-[10px] text-white">MW</span>
                </div>
              ` : ''}
            </div>
            ${(inAlarm > 0 && inCritAlarm == 0) ? `<div class="w-7 h-7 absolute -bottom-1 -right-1 flex justify-center items-center aspect-squar rounded-full bg-gs-warning"><img class="h-5 w-5 alarm-icon-white"></div>` : ''}
            ${(inCritAlarm > 0) ? `<div class="w-7 h-7 absolute -bottom-1 -right-1 flex justify-center items-center aspect-squar rounded-full bg-gs-danger"><img class="h-5 w-5 alarm-icon-white"></div>` : ''}
            ${(disconnected == count) ? `<div class="w-7 h-7 absolute -bottom-1 -left-1 border-1 flex justify-center items-center aspect-squar rounded-full bg-black"><span class="tracking-tighter leading-none font-medium text-sm text-white">${count}</span></div>` : ''}
            ${(disconnected < count && disconnected > 0) ? `<div class="w-9 h-7 absolute -bottom-1 -left-2 border-1 flex justify-center items-center aspect-square rounded-full bg-not-running-disconnected"><span class="tracking-tighter leading-none font-medium text-sm text-white">${disconnected}/${count}</span></div>` : ''}
            ${(disconnected == 0) ? `<div class="w-7 h-7 absolute -bottom-1 -left-2 border-1 flex justify-center items-center aspect-square rounded-full bg-not-running-disconnected"><span class="tracking-tighter leading-none font-medium text-sm text-white">${count}</span></div>` : ''}
            ${(running > 0) ? `<div class="w-9 h-7 absolute -top-1 -left-2 border-1 flex justify-center items-center aspect-square rounded-full bg-running"><span class="tracking-tighter leading-none font-medium text-sm text-white">${running}/${count}</span></div>` : ''}
            </div>`;
            return L.divIcon({ 'html': iconHTML, 'className': 'flex justify-center items-center leaflet-div-icon', 'iconSize': L.point(48, 48) });
          }
        }
      }
    }

    addLayerDelay(layer) {
      this.layers.overlays[layer.id] = {
        name: layer.name,
        url: layer.url ? layer.url : undefined,
        type: layer.type,
        opacity: layer.opacity ? Number(layer.opacity) : 1.0,
        visible: layer.visible ? layer.visible : undefined,
        doRefresh: layer.url ? false : undefined,
        updateInterval: 30000,
        updateWhenIdle: true,
        layerOptions: {
          disableClusteringAtZoom: 14,
          spiderfyDistanceMultiplier: 2
        }
      }
    }

    refreshVisibleLayers() {
      for (let layer in this.layers.overlays) {
        if (this.layers.overlays[layer].type.indexOf('xyz') > -1) {
          if (this.layers.overlays[layer].visible) {
            this.layers.overlays[layer].doRefresh = true;
          }
        }
      }
      this.refreshStormLayers();
    }

    refreshStormLayers() {
      this.SystemResource.liststormlayers().$promise.then(results => {
        if (results) {
          this.leafletData.getMap().then(map => {
            if (this.showStorms) {
              for (let layerid in this.stormLayers) {
                map.removeLayer(this.stormLayers[layerid]);
              }
            }
            this.stormLayers = [];
            for (let storm of results) {
              const parser = new DOMParser();
              const kml = parser.parseFromString(storm.layerData, 'text/xml');
              this.stormLayers[storm.code + storm.layerId] = new L['KML'](kml);
            }
            if (this.showStorms) {
              for (let layerid in this.stormLayers) {
                map.addLayer(this.stormLayers[layerid]);
              }
            }
          });
        }
      });
    }

    showSite = (markerId) => {
      if ((this.notableCommaFilter(this.searchText.toLowerCase(), this.siteList[markerId].Name.toLowerCase())
        && ((this.showDisconnected) || (!this.showDisconnected && this.allmarkers[markerId].connected))
        && ((this.showMobiles) || (!this.showMobiles && this.allmarkers[markerId].type != 'MOBILE'))
        && ((this.showMicroGrid) || (!this.showMicroGrid && this.allmarkers[markerId].type != 'MICRO_GRID'))
        && ((this.showGenOnly) || (!this.showGenOnly && this.allmarkers[markerId].type != 'GENERATOR_ONLY'))
        && ((this.showDataCenter) || (!this.showDataCenter && this.allmarkers[markerId].type != 'DATA_CENTER'))
        && ((this.showBESS) || (!this.showBESS && this.allmarkers[markerId].type != 'BESS'))
        && ((this.showOther) || (!this.showOther && this.allmarkers[markerId].type != 'UNKNOWN'))
        && ((this.showInAlarm) || (!this.showInAlarm && (!this.allmarkers[markerId].inAlarm && !this.allmarkers[markerId].inCritAlarm)))
        && ((this.showNoAlarm) || (!this.showNoAlarm && (this.allmarkers[markerId].inAlarm || this.allmarkers[markerId].inCritAlarm)))
        && ((this.showStopped) || (!this.showStopped && this.isTrue(this.allmarkers[markerId].running)))
        && ((this.showRunning) || (!this.showRunning && this.isFalse(this.allmarkers[markerId].running)))
      ) && (
        (!this.showLowFuel && !this.showCritLowFuel && !this.showLowDEF && !this.showCritLowDEF)
        || (this.showLowFuel && this.isTrue(this.allmarkers[markerId].lowFuelAlarm))
        || (this.showCritLowFuel && this.isTrue(this.allmarkers[markerId].critLowFuelAlarm))
        || (this.showLowDEF && this.isTrue(this.allmarkers[markerId].lowDEFAlarm))
        || (this.showCritLowDEF && this.isTrue(this.allmarkers[markerId].critLowDEFAlarm))
      )) {
        return true;
      }
      return false;
    }

    // Adjust markers by filter text, removing markers from the map and updating the charts.
    updateVisible(filterChange = false) {
      let self = this;
      let onMap = [];
      let filteredSites = [];
      for (let markerId in self.allmarkers) {
        if (self.showSite(markerId)) {
          self.markers[markerId] = angular.copy(self.allmarkers[markerId]);
          filteredSites.push(markerId);
        } else {
          delete self.markers[markerId];
        }
      }
      // Remove any sites that are not in view on the map from the visibleMarkers / visibleSites list.
      let noMarkerLayer = true;
      self.leafletData.getMap().then(map => {
        map.eachLayer(layer => {
          if (layer.options.hasOwnProperty('title')) {
            noMarkerLayer = false;
            if (map.getBounds().contains(layer.getLatLng())) {
              onMap.push(layer.options.title);
            }
          }
          if (layer.hasOwnProperty('_childCount')) {
            noMarkerLayer = false;
            let markers = layer.getAllChildMarkers();
            for (let marker of markers) {
              if (map.getBounds().contains(marker.getLatLng())) {
                onMap.push(marker.options.title);
              }
            }
          }
        });
      }).finally(() => {
        // Deal with first load to populate the overview.  The map has not processed the marker layers at this point.
        if (noMarkerLayer || (filterChange && this.lockSitesToMap) || !this.lockSitesToMap) {
          onMap = filteredSites;
        }
        //consolidate visibleMarkers
        this.offMapMarkers = filteredSites.reduce((acc, curr) => {
          if (!onMap.includes(curr)) {
            acc.push(curr);
          }
          return acc;
        }, []);
        let visibleSites = filteredSites.reduce((acc, curr) => {
          if (onMap.includes(curr)) {
            acc.push(curr);
          }
          return acc;
        }, []);
        if (self.visbleMarkers.toString() != visibleSites.toString()) {
          self.visbleMarkers = [];
          self.visibleSites = [];
          visibleSites.forEach((siteId) => {
            this.addMarkerToList(siteId);
          });
          self.updateClusters();
          self.getOverviewChartData();
          // Turned off auto zoom to single site.
          // if (self.visbleMarkers.length == 1) {
          //   self.gotoMarker(self.visbleMarkers[0]);
          // }
        }
      });
    }

    gotoMarker(site) {
      this.leafletData.getMap().then(map => {
        let latLngs = [L.latLng(this.markers[site].lat, this.markers[site].lng)];
        let markerBounds = L.latLngBounds(latLngs);
        map.fitBounds(markerBounds);
      });
    }

    zoomToBounds() {
      this.leafletData.getMap().then(map => {
        map.fitBounds(this.maxbounds);
      }).finally(() => {
        this.updateVisible();
      });
    }

    setupCharts = () => {
      let self = this;
      const overviewchart = document.getElementById('powerProfileChart') as HTMLCanvasElement;
      const opReserverChart = document.getElementById('opReserverChart') as HTMLCanvasElement;
      const emPieChart = document.getElementById('energyMixChart') as HTMLCanvasElement;

      const overviewChartCanvas = overviewchart.getContext('2d');
      const opReserverChartCanvas = opReserverChart.getContext('2d');
      const emPieChartCanvas = emPieChart.getContext('2d');

      self.ppLineChart = new Chart(overviewChartCanvas, self.ppChartSetup);
      self.orLineChart = new Chart(opReserverChartCanvas, self.orChartSetup);
      self.emPieChart = new Chart(emPieChartCanvas, self.emChartSetup);
    }

    setupPanelEvents = () => {
      let self = this;
      const overviewPane = document.getElementById('overviewPane');
      const siteListPane = document.getElementById('siteListPane');
      overviewPane.addEventListener("hidden.bs.collapse", function (event) {
        if(event.target['id'] == 'overviewPane') {
          self.$cookies.put('showOverview', false);
          self.showOverview = false;
          self.refreshMap();
        }
      }, true);
      siteListPane.addEventListener("hidden.bs.collapse", function (event) {
        if(event.target['id'] == 'siteListPane') {
          self.$cookies.put('showSiteList', false);
          self.showSiteList = false;
          self.refreshMap();
        }
      }, true);
      overviewPane.addEventListener("shown.bs.collapse", function (event) {
        if(event.target['id'] == 'overviewPane') {
          self.$cookies.put('showOverview', true);
          self.showOverview = true;
          self.refreshMap();
        }
      }, true);
      siteListPane.addEventListener("shown.bs.collapse", function (event) {
        if(event.target['id'] == 'siteListPane') {
          self.$cookies.put('showSiteList', true);
          self.showSiteList = true;
          self.refreshMap();
        }
      }, true);
    }

    toggleStorms = () => {
      this.leafletData.getMap().then(map => {
        for (let layerid in this.stormLayers) {
          if (this.showStorms) {
            map.addLayer(this.stormLayers[layerid]);
          } else {
            map.removeLayer(this.stormLayers[layerid]);
          }
        }
      });
    }

    setPanelsOnLoad = () => {
      if (!this.showSiteList) {
        const siteListPane = document.getElementById('siteListPane');
        const siteList = new bootstrap.Collapse(siteListPane, { toggle: false });
        siteList.hide();
      }
      if (!this.showOverview) {
        const overviewPane = document.getElementById('overviewPane');
        const overview = new bootstrap.Collapse(overviewPane, { toggle: false });
        overview.hide();
      }
    }

    applyFilters = (save = true) => {
      if (this.showStorms != this.filter.storms) {
        this.showStorms = this.filter.storms;
        this.toggleStorms();
      }
      this.applyGlobalFilters(save);
      if (save) {
        $('#siteFilterDropdown').dropdown('toggle');
      }
      this.updateVisible(true);

    }

    cancelFilters = () => {
      this.cancelGlobalFilters();
      $('#siteFilterDropdown').dropdown('toggle');
    }

    getData = (first: boolean = false) => {
      this.busy = true;
      let selectPromises = [];
      selectPromises.push(this.SiteResource.listlive().$promise);
      this.$q.all(selectPromises).then(results => {
        if (results[0]) {
          this.upsertMarkers(results[0], first);
          this.refreshMap();
        }
        this.busy = false;
      });
    }

    updateClusters = () => {
      let self = this;
      self.leafletData.getMap().then(map => {
        map.eachLayer(layer => {
          if (layer.hasOwnProperty('_markerCluster')) {
            layer._markerCluster.prototype._updateIcon();
          }
        });
      });
    }

    clearSearch = () => {
      this.searchText = '';
      this.zoomToBounds();
    }

    setListeners = () => {
      for (let k in this.mapEvents.map.enable) {
        let eventName = 'leafletDirectiveMap.' + this.mapEvents.map.enable[k];
        this.$scope.$on(eventName, event => {
          this.updateVisible();
        });
      }
    }

    onOverlayAdd = (e) => {
      if (this.visibleLayers.hasOwnProperty(e.name)) {
        this.visibleLayers[e.name] = true;
      }
    }

    onOverlayRemove = (e) => {
      if (this.visibleLayers.hasOwnProperty(e.name)) {
        this.visibleLayers[e.name] = false;
      }
    }

    addMarkerToList = (markerId) => {
      if (!this.visbleMarkers.includes(markerId)) {
        this.visbleMarkers.push(markerId)
        this.visibleSites.push(markerId.replace('S_', ''));
      }
    }

    isJSON = (json) => {
      const countCharacter = (string, character) => {
        let count = 0;
        for (let i = 0; i < string.length; i++) {
          if (string.charAt(i) == character) { //counting : or ,
            count++;
          }
        }
        return count;
      }
      json = json.trim(); // remove whitespace, start and end spaces

      //check starting and ending brackets
      if (json.charAt(0) != '{' || json.charAt(json.length - 1) != '}') {
        return false
      }
      //else this line will check whether commas(,) are one less than colon(:)
      else if ((countCharacter(json, ':') - 1 != countCharacter(json, ','))) {
        return false;
      } else {
        json = json.substring(1, json.length - 1); //remove first and last brackets
        json = json.split(','); //split string into array, and on each index there is a key-value pair

        //this line iterate the array of key-value pair and check whether key-value string has colon in between
        json.forEach((pairs) => {
          if (pairs.indexOf(':') == -1) { //if colon not exist in b/w
            return false;
          }
        });
      }
      return true;
    };

    statusFormat = (input) => {
      if (_.isUndefined(input) || _.isNull(input)) {
        return 'n/a';
      }
      let rtn = input;
      if (this.isJSON(input)) {
        rtn = "";
        let statObj = JSON.parse(input);
        Object.keys(statObj).forEach((key) => {
          if (rtn.length > 0) {
            rtn += ', ';
          }
          rtn += key + ":" + statObj[key];
        });
      } else {
        rtn = input.replace(/"/g, "");
      }
      return rtn;
    }

    getLoad = (out, max) => {
      if (max == 0) {
        return null;
      }
      if (_.isNaN(out) || _.isNaN(max)) {
        return null;
      }
      return (out / max * 100.0).toFixed(1);
    }

    updateSiteSummaryData = (site) => {
      let summaryTempMain = {};
      if (site.hasOwnProperty("iodata")) {
        if (site.iodata.hasOwnProperty('tags')) {
          for (const unitType in site.iodata.tags) {
            if (typeof site.iodata.tags[unitType] === 'object') {
              let summaryTemp = { 'name': '', 'kw': 0, 'count': 0, 'operating': 0, 'size': 0 };
              summaryTemp['name'] = this.getUnitTypeName(unitType);
              for (const unit in site.iodata.tags[unitType]) {
                if (typeof site.iodata.tags[unitType][unit] === 'object') {
                  if (site.iodata.tags[unitType][unit].hasOwnProperty('kw')) {
                    if (!this.summarydata.hasOwnProperty(unitType)) {
                      this.summarydata[unitType] = { 'name': '', 'kw': 0, 'count': 0, 'operating': 0, 'size': 0 };
                      this.summarydata[unitType]['name'] = this.unitTypes.find((type) => type.value == unitType).name;
                    }
                    summaryTemp['kw'] = summaryTemp['kw'] + site.iodata.tags[unitType][unit]['kw'];
                    this.summarydata[unitType]['kw'] = this.summarydata[unitType]['kw'] + site.iodata.tags[unitType][unit]['kw'];
                    if (site.iodata.tags[unitType][unit].hasOwnProperty('size')) {
                      summaryTemp['size'] = summaryTemp['size'] + site.iodata.tags[unitType][unit]['size'];
                      this.summarydata[unitType]['size'] = this.summarydata[unitType]['size'] + site.iodata.tags[unitType][unit]['size'];
                    }
                    summaryTemp['count']++;
                    this.summarydata[unitType]['count']++;
                    if (this.isTrue(site.iodata.tags[unitType][unit]['running'])) {
                      summaryTemp['operating']++;
                      this.summarydata[unitType]['operating']++;
                    }
                  }
                }
              };
              summaryTempMain[unitType] = summaryTemp;
            }
          };
        }
      }
      return summaryTempMain;
    }

    refreshMap = () => {
      this.leafletData.getMap().then(map => {
        map._onResize();
      });
    }

    setChartData = (duration = null) => {
      if (duration == null) {
        this.activeOverviewChart = this.$cookies.get('chartDuration');
        if (this.activeOverviewChart == null) {
          this.activeOverviewChart = 24;
        }
      } else {
        this.activeOverviewChart = duration;
      }
      this.$cookies.put('chartDuration', this.activeOverviewChart);
      let chartData = [];
      let pieData = [];
      this.ppChartDatasets = [];
      this.orChartDatasets = [];
      this.emPieChart.data.datasets = [];

      pieData.push({
        backgroundColor: this.emChartColors,
        data: [this.overviewChartData['utilityLoad'].percent.value,
          0, 0, 0,
          this.overviewChartData['bessGeneration'].percent.value,
          this.overviewChartData['solarPvGeneration'].percent.value,
          this.overviewChartData['windGeneration'].percent.value,
          this.overviewChartData['naturalGasGeneratorsGeneration'].percent.value,
          this.overviewChartData['dieselGeneratorsGeneration'].percent.value,
          this.overviewChartData['steamTurbinesGeneration'].percent.value,
          this.overviewChartData['gasTurbinesGeneration'].percent.value,
          this.overviewChartData['fuelCellGeneration'].percent.value,
          this.overviewChartData['otherGeneration'].percent.value
        ]
      });
      pieData.push({
        backgroundColor: this.emChartColors,
        data: [
          this.overviewChartData['utilityLoad'].percent.value,
          0,
          this.overviewChartData['renewableGeneration'].percent.value,
          this.overviewChartData['nonRenewableGeneration'].percent.value
        ]
      });
      pieData.push({
        backgroundColor: this.emChartColors,
        data: [
          this.overviewChartData['utilityLoad'].percent.value,
          this.overviewChartData['assetLoad'].percent.value
        ]
      });
      this.emPieChart.data.labels = this.emChartLabels;
      this.emPieChart.data.datasets = pieData;
      let oldestDate = luxon.DateTime.now().minus({ hours: this.activeOverviewChart });
      this.overviewChartDataFull.forEach((record) => {
        let tzDate = luxon.DateTime.fromISO(record.x).setZone(this.timeZone);
        if (oldestDate < tzDate) {
          let newDataRec = { x: tzDate };
          newDataRec['demand'] = record['demand'].kw.value;
          newDataRec['assetProduction'] = record['assetProduction'].kw.value;
          newDataRec['utilityConsumption'] = record['utilityConsumption'].kw.value;
          newDataRec['assetCapacityInOperations'] = record['assetCapacityInOperations'].percent.value;
          newDataRec['assetLoad'] = record['assetLoad'].percent.value;
          newDataRec['utilityLoad'] = record['utilityLoad'].percent.value;
          chartData.push(newDataRec);
        }
      });
      this.ppChartDatasets.push({ label: 'Demand', data: chartData, parsing: { yAxisKey: 'demand' }, fill: false, borderColor: this.ppChartColors[0] });
      this.ppChartDatasets.push({ label: 'Assets', data: chartData, parsing: { yAxisKey: 'assetProduction' }, fill: true, borderColor: this.ppChartColors[1] });
      this.ppChartDatasets.push({ label: 'Utility', data: chartData, parsing: { yAxisKey: 'utilityConsumption' }, fill: true, borderColor: this.ppChartColors[2] });
      this.orChartDatasets.push({ type: 'bar', label: 'In Ops', data: chartData, parsing: { yAxisKey: 'assetCapacityInOperations' }, fill: false, borderColor: this.orChartColors[0] });
      this.orChartDatasets.push({ type: 'line', label: 'from Assets', data: chartData, parsing: { yAxisKey: 'assetLoad' }, fill: true, borderColor: this.orChartColors[1] });
      this.orChartDatasets.push({ type: 'line', label: 'from Utility', data: chartData, parsing: { yAxisKey: 'utilityLoad' }, fill: true, borderColor: this.orChartColors[2] });
      this.updateCharts();
    }

    // Only gets called when the site list changes or the 5 min timer hits.
    getOverviewChartData = () => {
      let self = this;
      self.ppChartDatasets = [];
      self.orChartDatasets = [];
      self.emPieChart.data.datasets = [];
      if (self.visibleSites.length > 0) {
        this.SiteResource.getDashboardChartData({ visibleSites: self.visibleSites.join(',') }).$promise.then(results => {
          if (results && results.length > 0) {
            self.overviewChartDataFull = results;
            self.overviewChartData = results[results.length - 1];
            this.setChartData();
          } else {
            this.overviewChartData = {};
            self.updateCharts();
          }
        });
      } else {
        this.overviewChartData = {};
        self.updateCharts();
      }
    }

    updateCharts = () => {
      this.ppLineChart.data.datasets = this.ppChartDatasets;
      this.orLineChart.data.datasets = this.orChartDatasets;
      this.ppLineChart.update();
      this.orLineChart.update();
      this.emPieChart.update();
    }

    upsertMarkers = (sites, first = false) => {
      this.lat = [];
      this.lng = [];
      this.siteList = {};
      this.summarydata = {};
      for (let site of sites) {
        this.addSiteMarker(site);
      }

      let min_coords = {
        lat: Math.min.apply(null, this.lat) - 1.0,
        lng: Math.min.apply(null, this.lng) - 1.0
      }
      let max_coords = {
        lat: Math.max.apply(null, this.lat) + 1.0,
        lng: Math.max.apply(null, this.lng) + 1.0
      }
      this.maxbounds = L.latLngBounds(L.latLng(min_coords.lat, min_coords.lng), L.latLng(max_coords.lat, max_coords.lng));
      if (first) {
        this.zoomToBounds();
      } else {
        this.updateVisible();
      }
    }

    addSiteMarker = (site) => {
      let connected = site.iodata ? site.iodata.connected : 0;
      let running = site.iodata ? site.iodata.tags.running : 0;
      let inAlarm = site.inAlarm ? site.inAlarm : 0;
      let inCritAlarm = site.inCritAlarm ? site.inCritAlarm : 0;
      let runningBg = 'bg-running';
      if (running && inAlarm && !inCritAlarm) {
        runningBg = 'bg-running-in-alarm';
      } else if (running && inCritAlarm ) {
        runningBg = 'bg-running-in-crit-alarm'
      }

      this.siteList[site.IoTDeviceId] = angular.copy(site);
      //Process the iodata to summarize each of the unit types.
      if (site.hasOwnProperty("iodata")) {
        let updateTime = luxon.DateTime.fromISO(this.siteList[site.IoTDeviceId].iodata.timestamp).setZone(this.timeZone).toFormat(this.dateFilterFormat);
        let tags = angular.copy(site.iodata.tags);
        this.siteList[site.IoTDeviceId]['summarydata'] = this.updateSiteSummaryData(site);
        let donut = this.getDonut(this.siteList[site.IoTDeviceId]['summarydata']);
        this.siteList[site.IoTDeviceId]['donut'] = donut;
        let load = 'n/a';
        if(this.siteList[site.IoTDeviceId].summarydata) {
          if(this.siteList[site.IoTDeviceId].summarydata.hasOwnProperty('L')) {
            load = (Math.round(this.siteList[site.IoTDeviceId].summarydata['L']['kw'] / 10) / 100).toFixed(2);
          }
        }
        if (this.siteList[site.IoTDeviceId].iodata.hasOwnProperty('location')) {
          if (!this.allmarkers.hasOwnProperty(site.IoTDeviceId)) {
            this.allmarkers[site.IoTDeviceId] = angular.copy(this.divIconMarkerClass);
          }
          this.allmarkers[site.IoTDeviceId].lat = this.siteList[site.IoTDeviceId].iodata.location.lat;
          this.allmarkers[site.IoTDeviceId].lng = this.siteList[site.IoTDeviceId].iodata.location.long;
          this.lat.push(this.siteList[site.IoTDeviceId].iodata.location.lat);
          this.lng.push(this.siteList[site.IoTDeviceId].iodata.location.long);
          this.allmarkers[site.IoTDeviceId].title = site.IoTDeviceId;
          let summarydata = '';
          Object.keys(this.siteList[site.IoTDeviceId]['summarydata']).forEach((unitType) => {
            if(this.siteList[site.IoTDeviceId]['summarydata'][unitType].hasOwnProperty('name')) {
              summarydata += `<div class="ui-grid-cell-table-th relative"><label>`+ this.siteList[site.IoTDeviceId]['summarydata'][unitType].name +`</label>` + this.statusFormat(tags[unitType].status) + `</div>`;
            }
          });
          this.allmarkers[site.IoTDeviceId].message = `
            <div class="w-72">
              <div class="flex flex-row justify-between w-full overflow-hidden gap-2">
                <div class="flex flex-col self-center w-full">
                  <div class="gs-site-info-text truncate">${site.Name}</div>
                  <div class="flex items-center gap-[8px] text-[10px] leading-[1.4] mt-[2px] text-text-disabled font-semibold shrink-0 ${connected == 1 ? 'visible' : 'collapse'} ">
                    <span class="flex items-center gap-[2px]">
                      <span class="w-[7px] aspect-square rounded-full bg-gs-success"></span>
                      <span class="text-text-med-em">${ this.capPercent(donut.nonrenewableasset + donut.renewableasset) }% Assets</span>
                    </span>
                    <span class="flex items-center gap-[2px]">
                      <span class="w-[7px] aspect-square rounded-full bg-gs-warning"></span>
                      <span class="text-text-med-em">${ this.capPercent(donut.util) }% Utility</span>
                    </span>
                  </div>
                  <div class="flex items-center gap-[8px] text-[10px] leading-[1.4] mt-[2px] text-text-disabled font-semibold shrink-0 ${connected == 0 ? 'visible' : 'collapse'}">
                    <span class="text-text-med-em">Disconnected</span>
                  </div>
                </div>
                <div class="flex flex-row">
                  <img class="alarm-icon origin-center ${(inAlarm && !inCritAlarm) ? 'visible' : 'collapse'}" title="Active Alarms"></img>
                  <img class="volume-up-icon filter-white origin-center ${inCritAlarm ? 'visible' : 'collapse'}" title="Active Critical Alarms"></img>
                </div>
              </div>
              <div class="p-0 ui-grid-header-canvas w-full flex flex-col gap-1">
                <div class="ui-grid-cell-table-th relative"><label>Updated</label>${updateTime}</div>
                <div class="ui-grid-cell-table-th relative"><label>Site</label>${this.statusFormat(tags.status)} ${this.statusFormat(tags.opStatus)}</div>
                ${ summarydata }
                <div class="flex flex-row gap-2 w-full ui-grid-cell-table-td">
                  <i class="fa-solid fa-temperature-half"></i>
                  <span class="origin-center">${this.tempConvert(site.WeatherJSON.main.temp)}${this.units === 'imperial' ? '&#8457;' : '&#8451'}</span>
                  <i class="fa-solid fa-wind"></i>
                  <i class="fa-solid fa-up-long fa-rotate-by" style="--fa-rotate-angle: ${site.WeatherJSON.wind.deg}deg;"></i>
                  <span class="origin-center">${this.speedConvert(site.WeatherJSON.wind.speed)}${this.units === 'imperial' ? 'mph' : 'km/h'}</span>
                  <img class="w${site.WeatherJSON.weather[0].icon} h-6"></img>
                  <span class="origin-center">${site.WeatherJSON.weather[0].main}</span>
                </div>
              </div>
            </div>
            `;

          let iconHTML = '';
          // If Not Running
          if (!running) {
            iconHTML = `
              <div class="w-16 h-16 flex justify-center items-center rounded-full relative shrink-0 bg-not-running-disconnected">
              <div class="w-full flex justify-center items-center aspect-square rounded-full overflow-hidden relative">
                <div class="absolute inset-0 rotate-[180deg]" style="mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(99% - 6px), rgb(0, 0, 0) calc(100% - 6px)); 
                  background-image: ${ donut.style }">
                </div>
                <div class="flex flex-col justify-center items-center">
                  <span class="tracking-tighter leading-none font-medium text-sm text-white">${load}</span>
                  <span class="tracking-tighter leading-none text-[10px] text-white">MW</span>
                </div>`;
          }
          // If Running
          if (running) {
            iconHTML = `
              <div class="w-16 h-16 flex justify-center items-center rounded-full relative shrink-0 ${ runningBg }">
                <div class="w-full flex justify-center items-center aspect-square rounded-full overflow-hidden relative">
                  <div class="absolute inset-0 rotate-[180deg]" style="mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(99% - 6px), rgb(0, 0, 0) calc(100% - 6px)); 
                    background-image: ${ donut.style }">
                  </div>
                  <div class="flex flex-col justify-center items-center">
                    <span class="tracking-tighter leading-none font-medium text-sm text-white">${load}</span>
                    <span class="tracking-tighter leading-none text-[10px] text-white">MW</span>
                  </div>`;
          }

          // Disconnected
          if (!connected) {
            iconHTML = `
              <div class="w-12 h-12 flex justify-center items-center rounded-full relative shrink-0 bg-not-running-disconnected">
              <div class="w-full flex justify-center items-center aspect-square rounded-full overflow-hidden relative">
                <img class="disconnected-icon">`;
          }
          iconHTML += `
            </div>
            ${(inAlarm && !inCritAlarm) ? `<div class="w-7 h-7 absolute -bottom-1 -right-1 flex justify-center items-center aspect-squar rounded-full bg-gs-warning"><img class="h-5 w-5 alarm-icon-white"></div>` : ''}
            ${inCritAlarm ? `<div class="w-7 h-7 absolute -bottom-1 -right-1 flex justify-center items-center aspect-squar rounded-full bg-gs-danger"><img class="h-5 w-5 alarm-icon-white"></div>` : ''}
          </div>
          `;
          this.allmarkers[site.IoTDeviceId].kwData = donut;
          this.allmarkers[site.IoTDeviceId].type = this.siteList[site.IoTDeviceId].Type;
          this.allmarkers[site.IoTDeviceId].connected = connected;
          this.allmarkers[site.IoTDeviceId].running = running;
          this.allmarkers[site.IoTDeviceId].inAlarm = inAlarm;
          this.allmarkers[site.IoTDeviceId].inCritAlarm = inCritAlarm;
          this.allmarkers[site.IoTDeviceId].lowFuelAlarm = site.lowFuelAlarm;
          this.allmarkers[site.IoTDeviceId].critLowFuelAlarm = site.critLowFuelAlarm;
          this.allmarkers[site.IoTDeviceId].lowDEFAlarm = site.lowDEFAlarm;
          this.allmarkers[site.IoTDeviceId].critLowDEFAlarm = site.critLowDEFAlarm;
          this.allmarkers[site.IoTDeviceId].icon.html = iconHTML;
          this.allmarkers[site.IoTDeviceId].layer = 'sites';
        }
      }
      // if(site.hasOwnProperty('children')) {
      //   this.addSiteMarkers(site.children, false);
      // }
    }
  }
  angular.module('gsapp').controller('MapController', MapController);
}