namespace GSapp.controllers {
  'use strict';

  export class UserController extends controllers.BaseController {
    busy: boolean = false;
    selectedUser: any = null;
    lockedOnly: boolean = false;
    passwordError: string = null;
    roleList: any = [];
    searchText: string = null;
    strongRegularExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
    mediumRegularExp = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/;
    pwdBackground: string = "bg-gs-dark";
    newPwdBackground: string = "bg-gs-dark";
    newPwdOk: boolean = false;
    passwordStrength: string = "";
    passwordsMatch: boolean = false;
    gridOptions: any = {};
    gridApi: any = {};
    newCommType: string = null;
    newCommValue: string = null;
    newCommOrder: number = 0;

    static readonly $inject = [
      'UserResource',
      'RoleResource',
      'LoginResource',
      'uiGridConstants',
      'SystemResource',
      '$window', 
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public UserResource: interfaces.IUserResource,
      public RoleResource: interfaces.IRoleResource,
      public LoginResource: interfaces.ILoginResource,
      public uiGridConstants: any,
      public SystemResource: interfaces.ISystemResource,   
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);
        
        let self = this;

        self.gridOptions['user'] = {
          onRegisterApi: function(gridApi){
            self.gridApi['user'] = gridApi;
          },
          enableFiltering: true,
          enableRowHashing:false,
          enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
          rowHeight: this.grid2RowHeight,
          data: [],
          columnDefs :[
            { name: 'User Login', field: 'LoginName', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Locked', field: 'Locked',width: '106',
              cellTemplate: `<div class="ui-grid-cell-contents flex justify-center"><img class="locked-icon h-10" ng-if="COL_FIELD"></img></div>`,
              filter: {
                term: null,
                type: uiGridConstants.filter.SELECT,
                selectOptions: [ { value: true, label: 'Locked' }, { value: false, label: 'Not Locked'} ]
              }
            },
            { name: 'First Name', field: 'FirstName', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Last Name', field: 'LastName', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Last Login', field: 'LastLogin',
              cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'},
            { name: 'Failed Logins', field: 'FailedCount', width: '126', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
            { name: 'Assigned Roles', field: 'Roles', filter: { condition: this.arrayNameFilter, placeholder: "Comma separated..."},
              cellTemplate: `<div class="ui-grid-cell-contents flex flex-wrap gap-1">
                <span class="ui-grid-cell-table-th flex-1" ng-repeat="role in row.entity.Roles">{{ role.Name }}</span>
                </div>`
            },
            { name: 'Comms', field: 'Comms',
              cellTemplate: `<div class="ui-grid-cell-contents flex flex-col overflow-y-auto text-sm">
                <div ng-repeat="comm in row.entity.Comms" class="flex flex-row">
                  <div class="w-1/4 ui-grid-cell-table-th">
                    {{ comm.Type }}
                  </div>
                  <div class="w-3/4 ui-grid-cell-table-td">
                    {{ comm.Value }}
                  </div>
                </div>
              </div>`
            },
            { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents m-0">
              <button class="w-full w-full m-0", type="button", id="userDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                <img class="hamburger-vertical-icon"><img>
              </button>
              <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="userDropdownMenuButton">
                <li ng-if="row.entity.AllowLogin">
                  <button class="dropdown-item" type="button", ng-click="grid.appScope.ctrl.sendReset(row.entity)">Send Reset</button>
                </li>
                <li ng-if="row.entity.AllowLogin">
                  <button class="dropdown-item" type="button", ng-click="grid.appScope.ctrl.clearMFA(row.entity)">Clear MFA</button>
                </li>
                <li>
                  <button class="dropdown-item" type="button", ng-click="grid.appScope.ctrl.toggleLogin(row.entity)">{{ row.entity.AllowLogin ? 'Disable Login' : 'Allow Login' }}</button>
                </li>
                <li ng-if="row.entity.Locked">
                  <button class="dropdown-item" type="button", ng-click="grid.appScope.ctrl.unlock(row.entity)">Unlock Account</button>
                </li>
                <li>
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.openEditModal(row.entity)">Edit Users</button>
                </li>
                <li>
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.openRoleModal(row.entity)">Assign Role</button>
                </li>
                <li>
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.openCommsModal(row.entity)">Edit Comms</button>
                </li>
              </ul></div>`
            }
          ]
        }
        self.getData();
      }

      filterLocked = (user) => {
        let show = true;
        if(this.lockedOnly) {
          show = user.Locked;
        }
        return show;
      }

      openEditModal = (user) => {
        this.selectedUser = JSON.parse(JSON.stringify(user));
        this.selectedUser['Password'] = null;
        this.selectedUser['Password2'] = null;
        this.passwordError = null;
        $("#editModal").modal("show");
      }

      updateUser = () => {
        if (this.selectedUser.id) {
          if (this.selectedUser.Password == null) {
            this.selectedUser.Password = undefined;
            this.selectedUser.Password2 = undefined;
          }
          this.saveUser();
        } else if (this.selectedUser.Password) {
          if (this.selectedUser.Password == this.selectedUser.Password2) {
            if (this.newPwdOk) {
              this.passwordError = null;
              this.createUser();
            } else {
              this.passwordError = "Password not strong enough.";
              toastr.error("Password is not strong enough.");
            }
          } else {
            this.passwordError = "Passwords do not match.";
            toastr.error("Passwords do not match.");
          }
        }

      }

      saveUser = () => {
        this.UserResource.update({id: this.selectedUser.id}, this.selectedUser).$promise
        .then(result => {
          if ( result ) {
            toastr.success('User ' + this.selectedUser.FirstName + ' updated');
            this.selectedUser = null;
            $("#editModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not update user ' + this.selectedUser.FirstName);
          }
        });
      }

      createUser = () => {
        this.UserResource.create(this.selectedUser).$promise
        .then(result => {
          if ( result ) {
            toastr.success('User ' + this.selectedUser.FirstName + ' created');
            this.selectedUser = null;
            $("#editModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not create user ' + this.selectedUser.FirstName);
          }
        });
      }

      openCreateModal = () => {
        this.selectedUser = {id: null, LoginName: null, FirstName: null, LastName: null, AllowLogin: true, Locked: false}
        this.selectedUser['Password'] = null;
        this.selectedUser['Password2'] = null;
        this.passwordError = null;
        $("#editModal").modal("show");
      }

      openRoleModal = (user) => {
        this.selectedUser = JSON.parse(JSON.stringify(user));
        this.UserResource.getUserRoles({id: user.id}).$promise.then(results => {
          this.roleList = results;
          $("#roleModal").modal("show");
        });
      }

      addComm = () => {
        this.selectedUser.Comms.push({
          id: null,
          UserId: this.selectedUser.id,
          Type: this.newCommType,
          Value: this.newCommType !== 'email' ? this.deformatPhoneNumber(this.newCommValue) : this.newCommValue,
          SortOrder: this.newCommOrder,
          deleted: false,
          new: true,
          dirty: true
        });
        this.newCommType = null;
        this.newCommValue = null;
        this.newCommOrder++;
      }

      deleteComm = (index) => {
        this.selectedUser.Comms[index]['deleted'] = true;
        this.selectedUser.Comms[index]['dirty'] = true;
      }

      moveUp = (index) => {
        if(index > 0) {
          let prevOrder = this.selectedUser.Comms[index].SortOrder;
          this.selectedUser.Comms[index].SortOrder = this.selectedUser.Comms[index-1].SortOrder;
          this.selectedUser.Comms[index-1].SortOrder = prevOrder;
          this.selectedUser.Comms[index]['dirty'] = true;
          this.selectedUser.Comms[index-1]['dirty'] = true;
        }
        this.selectedUser.Comms = this.sortComms(this.selectedUser.Comms);
      }

      moveDown = (index) => {
        if(index <= this.selectedUser.Comms.length) {
          let prevOrder = this.selectedUser.Comms[index].SortOrder;
          this.selectedUser.Comms[index].SortOrder = this.selectedUser.Comms[index+1].SortOrder;
          this.selectedUser.Comms[index+1].SortOrder = prevOrder;
          this.selectedUser.Comms[index]['dirty'] = true;
          this.selectedUser.Comms[index+1]['dirty'] = true;
        }
        this.selectedUser.Comms = this.sortComms(this.selectedUser.Comms);
      }

      sortComms = (comms) => {
        return comms.sort((a,b) => {
          return a.SortOrder - b.SortOrder;
        });
      }

      saveComms = ()  => {
        this.busy = true;
        let selectPromises = [];
        for (const comm of this.selectedUser.Comms) {
          if(comm.deleted) {
            selectPromises.push(this.UserResource.deleteComm({ id: comm.id }).$promise);
          } else if (comm.new) {
            selectPromises.push(this.UserResource.addComm(comm).$promise);
          } else if (comm.dirty) {
            selectPromises.push(this.UserResource.updateComm({ id: comm.id }, comm).$promise)
          }
        }
        this.$q.all(selectPromises).then((results: any[][]) => {
          this.busy = false;
          toastr.success("Comms saved.");
          $("#commsModal").modal("hide");
          this.getData();
        });
      }

      openCommsModal = (user) => {
        this.selectedUser = JSON.parse(JSON.stringify(user));
        // verify all roles have an order.
        this.newCommOrder = this.selectedUser.Comms.length;
        $("#commsModal").modal("show");
      }

      saveRoles = () => {
        // Send the list of roleIds + userId to process.
        let userRoles = [];
        for (let role of this.roleList) {
          if(role.Active) {
            userRoles.push(role.id);
          }
        }
        this.UserResource.setUserRoles({id: this.selectedUser.id, roles: JSON.stringify(userRoles)}).$promise.then(result => {
          if (result) {
            toastr.success('Roles assigned.');
            this.selectedUser = null;
            this.roleList = null;
            $("#roleModal").modal("hide");
            this.getData();
          } else {
            toastr.error('Could not update roles for ' + this.selectedUser.FirstName);
          }
        })
      }

      getData = () => {
        this.busy = true;

        this.UserResource.list().$promise
          .then(results => {
            if (results) {
              this.gridOptions['user'].data = results;
            }
            this.busy = false; 
          });
      }

      unlock = (user) => {
        let valuesToUpdate = {
          Locked: 0,
          FailedCount: 0
        }
        this.UserResource.update({id: user.id}, valuesToUpdate).$promise.then(result => {
          if (result) {
            toastr.success('User ' + user.FirstName + ' unlocked.');
            this.getData();
          } else {
            toastr.error('Could not unlock user ' + user.FirstName);
          }
        });
      }

      clearMFA = (user) => {
        let valuesToUpdate = {
          Secret: null
        }
        this.UserResource.update({id: user.id}, valuesToUpdate).$promise.then(result => {
          if (result) {
            toastr.success('User ' + user.FirstName + ' MFA reset.');
            this.getData();
          } else {
            toastr.error('Could not reset MFA for user ' + user.FirstName);
          }
        });
      }

      sendReset = (user) => {
        let self = this;
        self.LoginResource.sendReset({LoginName: user.LoginName}).$promise.then(result => {
          if(result.success) {
            toastr.success(result.message);  
          } else {
            toastr.error(result.message);  
          }
        }).catch(error => {
          toastr.error(error.data.message);
        });
      }

      toggleLogin = (user) => {
        let valuesToUpdate = {
          AllowLogin: !user.AllowLogin
        }
        this.UserResource.update({id: user.id}, valuesToUpdate).$promise.then(result => {
          if (result) {
            toastr.success('User ' + user.FirstName + ' login ' + valuesToUpdate.AllowLogin ? 'enabled.' : 'disabled.');
            this.getData();
          } else {
            toastr.error('Could not update user ' + user.FirstName);
          }
        });
      }

      checkNewPassword = (password) => {
        this.newPwdOk = false;
        this.passwordStrength = "WEAK";
        if (this.strongRegularExp.test(password)) {
          this.newPwdOk = true;
          this.passwordStrength = "STRONG";
        } else if (this.mediumRegularExp.test(password)) {
          this.newPwdOk = true;
          this.passwordStrength = "MEDIUM";
        }
      }

      comparePasswords = (password, newpassword) => {
        this.passwordsMatch = false;
        if (password == newpassword) {
          this.passwordsMatch = true;
        }
      }

  }
  angular.module('gsapp').controller('UserController', UserController);
}