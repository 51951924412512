namespace GSapp.controllers {
  'use strict';

  export class BaseController {
    timeZones: any[];
    countryList: any[];
    userToken: boolean;
    rootlistStyle: boolean = true;
    roles: string[];
    timeZone: string = 'America/New_York';
    roleAccess: { "admin": boolean, "mon": boolean, "svc": boolean };
    locals: Object;
    unitTypes: any;
    gridRowHeight: number = 55;
    grid2RowHeight: number = 110;
    grid3RowHeight: number = 165;
    dateFilterFormat: string = "yyyy-MM-dd HH:mm Z";
    dateOnlyFilterFormat: string = "yyyy-MM-dd";
    timeOnlyFilterFormat: string = 'HH:mm Z';
    chartDateFormat: string = "ccc dd hh:mm Z";
    chartSecondFormat: string = "ccc dd hh:mm:ss Z";
    varRefs: any;
    theme: string = 'light';
    units: string = 'imperial';
    currentDate: any = new Date();
    filter: any = {
      storms: true,
      disconnected: true,
      site: '',
      mobiles: true,
      microgrid: true,
      genonly: true,
      bess: true,
      datacenter: true,
      other: true,
      lockSitesToMap: true,
      running: true,
      stopped: true,
      inalarm: true,
      noalarm: true,
      lowfuel: false,
      critlowfuel: false,
      lowdef: false,
      critlowdef: false
    }
    activeOverviewChart: number = 24;
    searchText: string = '';
    showDisconnected: boolean = true;
    showMobiles: boolean = true;
    showMicroGrid: boolean = true;
    showGenOnly: boolean = true;
    showBESS: boolean = true;
    showDataCenter: boolean = true;
    showOther: boolean = true;
    lockSitesToMap: boolean = true;
    showStorms: boolean = true;
    showSiteList: boolean = true;
    showOverview: boolean = true;
    showRunning: boolean = true;
    showStopped: boolean = true;
    showInAlarm: boolean = true;
    showNoAlarm: boolean = true;
    showLowFuel: boolean = false;
    showCritLowFuel: boolean = false;
    showLowDEF: boolean = false;
    showCritLowDEF: boolean = false;

    static readonly $inject = [
      'SystemResource',
      '$window',
      '$q',
      '$cookies',
      'ReportResource'
    ];

    constructor(
      public SystemResource: interfaces.ISystemResource,
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource) {

      this.checkToken();
      this.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.getLists();
      this.loadCookies();

      setInterval( () => { 
        this.checkToken();
      }, 5000 );
    }

    allArraysAlike = (arrays) => {
      return _.all(arrays, function(array) {
        return array.length == arrays[0].length && _.difference(array, arrays[0]).length == 0;
      });
    }

    gotoSite = (site) => {
      if(site.ExternalViewLink !== null) {
        this.$window.location.href=site.ExternalViewLink;
      } else {
        this.$window.location.href='/site/' + site.id;
      }
    }

    checkToken = () => {
      let self = this;
      this.SystemResource.validateToken().$promise.then((response) => {
        self.userToken = null;
        if(response.userId) {
          self.userToken = true;
        }
        if(!self.userToken) {
          self.$window.location.href = "/login";
        } else {
          self.setRoleAccess(response.userRoles, response.userId);
        }
      });
    }

    setRoleAccess = (roles, userid) => {
      this.roles = roles;
      this.roleAccess = { "admin": false, "mon": false, "svc": false };
      for (let role of this.roles) {
        if (role == 'Admin') {
          this.roleAccess.admin = true;
        }
        if (role == 'Monitoring') {
          this.roleAccess.mon = true;
        }
        if (role == 'Service') {
          this.roleAccess.svc = true;
        }
      }
    }
    
    validateEmail = (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }

    capPercent = (value) => {
      if(value < 0) {
        return 0;
      }
      if(value > 100) {
        return 100;
      }
      return value;
    }
    
    notableCommaFilter = (term: string, value: string) => {
      let found = false;
      const isNot = term.startsWith('!');
      term = term.toLocaleLowerCase().replace(/!/g, '').replace(/\\x2d/g, "-");
      if (!term) {
        found = true;
      } else {
        if (isNot) {
          found = true;
        }
        let searchTerms = term.toLocaleLowerCase().replace(/\\/g, "").split(",")
        searchTerms.forEach((singleTerm) => {
          if (singleTerm) {
            if (value) {
              let foundindex = value.toLocaleLowerCase().indexOf(singleTerm.replace('!', '').trim());
              if (foundindex > -1) {
                found = !isNot;
              }
            }
          }
        });
      }
      return found;
    }

    applyGlobalFilters = (save = true) => {
      this.showDisconnected = this.filter.disconnected;
      this.showMobiles = this.filter.mobiles;
      this.showMicroGrid = this.filter.microgrid;
      this.showGenOnly = this.filter.genonly;
      this.showDataCenter = this.filter.datacenter;
      this.showBESS = this.filter.bess;
      this.showOther = this.filter.other;
      this.searchText = this.filter.site;
      this.lockSitesToMap = this.filter.lockSitesToMap;
      this.showStorms = this.filter.storms;
      this.showInAlarm = this.filter.inalarm;
      this.showNoAlarm = this.filter.noalarm;
      this.showStopped = this.filter.stopped;
      this.showRunning = this.filter.running;
      this.showLowFuel = this.filter.lowfuel;
      this.showCritLowFuel = this.filter.critlowfuel;
      this.showLowDEF = this.filter.lowdef;
      this.showCritLowDEF = this.filter.critlowdef;
      if (save) {
        this.$cookies.putObject('filters', this.filter);
      }
    }

    cancelGlobalFilters = () => {
      this.filter.disconnected = this.showDisconnected;
      this.filter.mobiles = this.showMobiles;
      this.filter.microgrid = this.showMicroGrid;
      this.filter.genonly = this.showGenOnly;
      this.filter.datacenter = this.showDataCenter;
      this.filter.bess = this.showBESS;
      this.filter.other = this.showOther;
      this.filter.site = this.searchText;
      this.filter.lockSitesToMap = this.lockSitesToMap;
      this.filter.storms = this.showStorms;
      this.filter.inalarm = this.showInAlarm;
      this.filter.noalarm = this.showNoAlarm;
      this.filter.stopped = this.showStopped;
      this.filter.running = this.showRunning;
      this.filter.lowfuel = this.showLowFuel;
      this.filter.critlowfuel = this.showCritLowFuel;
      this.filter.lowdef = this.showLowDEF;
      this.filter.critlowdef = this.showCritLowDEF;
    }

    setUnits = () => {
      this.units = this.units === 'imperial' ? 'metric' : 'imperial';
      this.$cookies.put('Units', this.units);
      this.$window.location.reload();
    }

    setDateFormat = (dformat) => {
      this.dateOnlyFilterFormat = dformat;
      this.dateFilterFormat = dformat + " HH:mm Z";
      this.$cookies.put('DateFormat', this.dateOnlyFilterFormat);
      this.$cookies.put('DateTimeFormat', this.dateFilterFormat);
      this.$window.location.reload();
    }

    setTheme = (theme) => {
      let removeTheme = theme === 'light' ? 'dark' : 'light';
      this.$window.document.body.classList.toggle(theme, true);
      this.$window.document.body.classList.toggle(removeTheme, false);
      this.theme = theme;
      this.$cookies.put('Theme', this.theme);
    }

    toggleDark = () => {
      let isLight = this.$window.document.body.classList.toggle("light");
      this.theme = isLight ? 'light' : 'dark';
      this.setTheme(this.theme);
    }

    formatPhoneNumber = (phoneNumberString) => {
      let cleaned = this.deformatPhoneNumber(phoneNumberString);
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = (match[1] ? '+1 ' : '');
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
      }
      return null;
    }

    deformatPhoneNumber = (phoneNumberString) => {
      return ('' + phoneNumberString).replace(/\D/g, '');
    }
    
    tempConvert = (value) => {
      let temp = value;
      if(_.isNumber(value)) {
        if(this.units === 'imperial') {
          temp = (value * 1.8) + 32;
        }
        temp = Math.round(temp * 10) / 10.0;
      }
      return temp;
    }

    speedConvert = (value) => {
      let speed = value;
      if(_.isNumber(value)) {
        if(this.units === 'imperial') {
          speed = (value * 0.6213711922);
        }
        speed = Math.round(speed * 10) / 10.0;
      }
      return speed;
    }

    lengthConvert = (value) => {
      let length = value;
      if(_.isNumber(value)) {
        if(this.units === 'imperial') {
          length = (value * 0.03937);
        }
        length = Math.round(length * 10) / 10.0;
      }
      return length;
    }

    volumeConvert = (value) => {
      let volume = value;
      if(_.isNumber(value)) {
        if(this.units === 'imperial') {
          volume = (value * 0.264172);
        }
        volume = Math.round(volume * 10) / 10.0;
      }
      return volume;
    }

    pressureConvert = (value) => {
      let pressure = value;
      if(_.isNumber(value)) {
        if(this.units === 'imperial') {
          pressure = (value * 0.01450377);
        }
        pressure = Math.round(pressure * 10) / 10.0;
      }
      return pressure;
    }

    arrayNameFilter = (term: string, value: any) => {
      let found = false;
      const isNot = term.startsWith('!');
      term = term.toLocaleLowerCase().replace(/!/g, '').replace(/\\x2d/g, "-");
      if (!term) {
        found = true;
      } else {
        if (isNot) {
          found = true;
        }
        let searchTerms = term.toLocaleLowerCase().replace(/\\/g, "").split(",")
        searchTerms.forEach((singleTerm) => {
          if (singleTerm) {
            value.forEach(item => {
              if (item.Name) {
                let foundindex = item.Name.toLocaleLowerCase().indexOf(singleTerm.replace('!', '').trim());
                if (foundindex > -1) {
                  found = !isNot;
                }
              }
            });
          }
        });
      }
      return found;
    }

    getReport = (name: string, format: string, options: any) => {
      let reportType;
      let streamFormat;
      let reportName = name;
      switch (format) {
        // case 'PDF':
        //   reportType = 'chrome-pdf';
        //   streamFormat = 'application/pdf';
        //   break;
        case 'HTML':
          reportType = 'html';
          streamFormat = 'text/html';
          break;
        case 'XLSX':
          reportType = 'xlsx';
          streamFormat = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          break;
        default:
          reportType = 'chrome-pdf';
          streamFormat = 'application/pdf';
          break;
      }
      this.ReportResource.getReport({ reportName: reportName, outputType: reportType, reportOptions: options }).$promise.then(reportOutput => {
        let reportBuff = new Uint8Array(reportOutput.data);
        let pageData = new Blob([reportBuff], { type: streamFormat });
        let urlObject = URL.createObjectURL(pageData);
        if (format == 'XLSX') {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.href = urlObject;
          a.download = name + luxon.DateTime.now().toFormat("yyyyLLddHHmm") + '.xlsx';
          a.click();
          URL.revokeObjectURL(a.href);
        } else {
          return urlObject;
        }
      });
    }

    loadCookies = () => {
      this.units = this.$cookies.get("Units") || 'imperial';
      this.theme = this.$cookies.get("Theme") || 'light';
      this.dateOnlyFilterFormat = this.$cookies.get("DateFormat") || "yyyy-MM-dd";
      this.dateFilterFormat = this.$cookies.get("DateTimeFormat") || "yyyy-MM-dd HH:mm Z";
      this.activeOverviewChart = this.$cookies.get('chartDuration') || 24;
      const filters = this.$cookies.getObject('filters');
      if (filters == undefined) {
        this.$cookies.putObject('filters', this.filter);
      }
      this.filter = this.$cookies.getObject('filters');
      const showSiteList = this.$cookies.get('showSiteList');
      if (showSiteList == undefined) {
        this.$cookies.put('showSiteList', true);
      }
      const showOverview = this.$cookies.get('showOverview');
      if (showOverview == undefined) {
        this.$cookies.put('showOverview', true);
      }
      this.showSiteList = this.$cookies.get('showSiteList') == 'true';
      this.showOverview = this.$cookies.get('showOverview') == 'true';
    }

    getSiteSummaryData = (site) => {
      let summaryTempMain = {};
      if (site.hasOwnProperty("iodata")) {
        if (site.iodata.hasOwnProperty('tags')) {
          for (const unitType in site.iodata.tags) {
            if (typeof site.iodata.tags[unitType] === 'object') {
              let summaryTemp = { 'name': '', 'kw': 0, 'count': 0, 'operating': 0, 'size': 0 };
              summaryTemp['name'] = this.getUnitTypeName(unitType);
              for (const unit in site.iodata.tags[unitType]) {
                if (typeof site.iodata.tags[unitType][unit] === 'object') {
                  if (site.iodata.tags[unitType][unit].hasOwnProperty('kw')) {
                    summaryTemp['kw'] = summaryTemp['kw'] + site.iodata.tags[unitType][unit]['kw'];
                    if (site.iodata.tags[unitType][unit].hasOwnProperty('size')) {
                      summaryTemp['size'] = summaryTemp['size'] + site.iodata.tags[unitType][unit]['size'];
                    }
                    summaryTemp['count']++;
                    if (this.isTrue(site.iodata.tags[unitType][unit]['running'])) {
                      summaryTemp['operating']++;
                    }
                  }
                }
              };
              summaryTempMain[unitType] = summaryTemp;
            }
          };
        }
      }
      return summaryTempMain;
    }

    getDonut = (data) => {
      let renewableassetsum = 0;
      let nonrenewableassetsum = 0;
      let utilsum = 0;
      let loadsum = null;
      Object.keys(data).forEach((unitType) => {
        if (['U'].includes(unitType)) {
          utilsum = utilsum + (_.isNumber(data[unitType].kw) ? data[unitType].kw : 0);
        }
        if (['SG', 'NG', 'TG', 'G', 'FC'].includes(unitType)) {
          nonrenewableassetsum = nonrenewableassetsum + (_.isNumber(data[unitType].kw) ? data[unitType].kw : 0);
        }
        if (['PV', 'W', 'I'].includes(unitType)) {
          renewableassetsum = renewableassetsum + (_.isNumber(data[unitType].kw) ? data[unitType].kw : 0);
        }
        if (['L'].includes(unitType)) {
          loadsum = loadsum + (_.isNumber(data[unitType].kw) ? data[unitType].kw : 0);
        }
      });
      if (loadsum == null) {
        loadsum = nonrenewableassetsum + renewableassetsum + utilsum;
      }
      let renewableasset = Math.round(renewableassetsum / loadsum * 100);
      let nonrenewableasset = Math.round(nonrenewableassetsum / loadsum * 100);
      let assets = this.capPercent(renewableasset + nonrenewableasset);
      let util = this.capPercent(Math.round(utilsum / loadsum * 100));
      let style = `conic-gradient(rgb(91, 196, 57) ` + assets + `%, rgba(0, 0, 0, 0) 0deg), conic-gradient(rgb(11, 171, 251) 0%, rgba(0, 0, 0, 0) 0deg), conic-gradient(rgb(252, 191, 4) ` + assets + util + `%, rgba(0, 0, 0, 0) 0deg`;
      return {
        renewableasset: renewableasset,
        rakw: renewableassetsum,
        nonrenewableasset: nonrenewableasset,
        nrakw: nonrenewableassetsum,
        util: util,
        utilkw: utilsum,
        loadkw: loadsum,
        style: style
      };
    }

    nameEmailPhoneFilter = (term: string, value: any, row: any) => {
      const field1 = row.entity.ContactName.toLocaleLowerCase();
      const field2 = row.entity.ContactEmail.toLocaleLowerCase();
      const field3 = row.entity.ContactPhone.toLocaleLowerCase();
      const isNot = term.startsWith('!');
      term = term.toLocaleLowerCase().replace(/\,\s/g, ",").replace(/!/g, '').replace(/\\x2d/g, "-");
      const searchTerms = term.split(",");

      let found = false;
      if (isNot) {
        found = true;
      }
      searchTerms.forEach(searchTerm => {
        const field1Idx = field1.indexOf(searchTerm.trim());
        const field2Idx = field2.indexOf(searchTerm.trim());
        const field3Idx = field3.indexOf(searchTerm.trim());

        if (field1Idx > -1 || field2Idx > -1 || field3Idx > -1) {
          found = !isNot;
        }
      });

      return found;
    }

    userFilter = (term: string, value: any, row: any) => {
      const field1 = row.entity.User.FirstName.toLocaleLowerCase();
      const field2 = row.entity.User.LastName.toLocaleLowerCase();
      const field3 = row.entity.User.LoginName.toLocaleLowerCase();
      const isNot = term.startsWith('!');
      term = term.toLocaleLowerCase().replace(/\,\s/g, ",").replace(/!/g, '').replace(/\\x2d/g, "-");
      const searchTerms = term.split(",");

      let found = false;
      if (isNot) {
        found = true;
      }
      searchTerms.forEach(searchTerm => {
        const field1Idx = field1.indexOf(searchTerm.trim());
        const field2Idx = field2.indexOf(searchTerm.trim());
        const field3Idx = field3.indexOf(searchTerm.trim());

        if (field1Idx > -1 || field2Idx > -1 || field3Idx > -1) {
          found = !isNot;
        }
      });

      return found;
    }

    addressFilter = (term: string, value: any, row: any) => {
      const field1 = row.entity.Address1.toLocaleLowerCase();
      const field2 = row.entity.Locality.toLocaleLowerCase();
      const field3 = row.entity.Region.toLocaleLowerCase();
      const field4 = row.entity.Postcode.toLocaleLowerCase();
      const field5 = row.entity.Country.toLocaleLowerCase();
      const isNot = term.startsWith('!');
      term = term.toLocaleLowerCase().replace(/\,\s/g, ",").replace(/!/g, '').replace(/\\x2d/g, "-");
      const searchTerms = term.split(",");

      let found = false;
      if (isNot) {
        found = true;
      }
      searchTerms.forEach(searchTerm => {
        const field1Idx = field1.indexOf(searchTerm.trim());
        const field2Idx = field2.indexOf(searchTerm.trim());
        const field3Idx = field3.indexOf(searchTerm.trim());
        const field4Idx = field4.indexOf(searchTerm.trim());
        const field5Idx = field5.indexOf(searchTerm.trim());

        if (field1Idx > -1 || field2Idx > -1 || field3Idx > -1 || field4Idx > -1 || field5Idx > -1) {
          found = !isNot;
        }
      });

      return found;
    }

    multiFieldFilterTemplate = (term: string, value: any, row: any) => {
      const field1 = row.entity.field1.toLocaleLowerCase();
      const field2 = row.entity.field2.toLocaleLowerCase();
      const isNot = term.startsWith('!');
      term = term.toLocaleLowerCase().replace(/\,\s/g, ",").replace(/!/g, '').replace(/\\x2d/g, "-");
      const searchTerms = term.split(",");

      let found = false;
      if (isNot) {
        found = true;
      }
      searchTerms.forEach(searchTerm => {
        const field1Idx = field1.indexOf(searchTerm.trim());
        const field2Idx = field2.indexOf(searchTerm.trim());

        if (field1Idx > -1 || field2Idx > -1) {
          found = !isNot;
        }
      });

      return found;
    }

    getLists = () => {
      let selectPromises = [];
      selectPromises.push(this.SystemResource.listtz().$promise);
      selectPromises.push(this.SystemResource.listcountry().$promise);
      selectPromises.push(this.SystemResource.listUnitTypes().$promise);
      selectPromises.push(this.SystemResource.listVarRefs().$promise);
      this.$q.all(selectPromises).then((results: any[][]) => {
        if (results[0]) {
          this.timeZones = results[0];
        }
        if (results[1]) {
          this.countryList = results[1];
        }
        if (results[2]) {
          this.unitTypes = results[2];
        }
        if (results[3]) {
          this.varRefs = results[3];
        }
      }).catch((errors: any) => {
      });
    }

    getTableHeight = (site?) => {
      if (site) {
        return { height: `378px` };
      }
      return { height: `99%` };
    };

    isTrue = (value) => {
      let rtn = false;
      if (value == 1 || value == 'ON' || value === true || value == '1') {
        rtn = true;
      }
      return rtn;
    }

    isFalse = (value) => {
      let rtn = false;
      if (value == 0 || value == 'OFF' || value === false || value == '0' || _.isUndefined(value) || _.isNull(value)) {
        rtn = true;
      }
      return rtn;
    }

    isJSON = (json) => {
      const countCharacter = (string, character) => {
        let count = 0;
        for (let i = 0; i < string.length; i++) {
          if (string.charAt(i) == character) { //counting : or ,
            count++;
          }
        }
        return count;
      }
      json = json.trim(); // remove whitespace, start and end spaces

      //check starting and ending brackets
      if (json.charAt(0) != '{' || json.charAt(json.length - 1) != '}') {
        return false
      }
      //else this line will check whether commas(,) are one less than colon(:)
      else if ((countCharacter(json, ':') - 1 != countCharacter(json, ','))) {
        return false;
      } else {
        json = json.substring(1, json.length - 1); //remove first and last brackets
        json = json.split(','); //split string into array, and on each index there is a key-value pair

        //this line iterate the array of key-value pair and check whether key-value string has colon in between
        json.forEach((pairs) => {
          if (pairs.indexOf(':') == -1) { //if colon not exist in b/w
            return false;
          }
        });
      }
      return true;
    };
    
    getUnitTypeName = (unitType) => {
      let rtn = 'Unknown';
      try {
        rtn = this.unitTypes.find((type) => type.value === unitType).name; 
      } catch (ex) {
        rtn = 'Error';
      }
      return rtn;
    }
  }
  angular.module('gsapp').controller('BaseController', BaseController);
}