namespace GSapp.controllers {
  'use strict';

  export class SiteController extends controllers.BaseController {
    busy: boolean = false;
    loading: boolean = false;
    updatingChart: boolean = false;
    siteData: any = null;
    searchText: string = null;
    oneLineGrid: any;
    oneLineGridString: string;
    oneLineGridData: {};
    activeTab: string = 'activeAlarm';
    showFillDetails: boolean = false;
    showUnitDetails: boolean = true;
    availableUnits = [{ value: null, label: "All Units" }];
    supplierList: any = [];
    selectedFill: any = null;
    selectedSite: any = null;
    selectedSupplier: any = null;
    selectedUnits: any = [];
    selectedUnitsJSON: any = {};
    activeEdit: boolean = false;
    gridOptions: any = {};
    gridApi: any = {};
    pauseLiveChart: boolean = false;

    requestedId: string = null;
    requestedType: string = null;
    requestedUnitId: string = null;
    availableCommands: any = null;
    selectedVar = 'kw';
    selectedVar2 = '';
    selectedHVar = 'kw';
    selectedHVar2 = '';
    historyDates: any = { 
      StartTime: moment().second(0).subtract(1, 'days'), 
      EndTime: moment().second(0), 
      Now: moment().second(0) 
    };

    alarmHistoryQuery: any = {
      unitId: null,
      alarmId: null,
      startTime: null,
      endTime: null
    }
    availableAlarms: any = [];
    locationString: string = '';
    //chartColors: any = ['#f4d166', '#e3cd62', '#d3c95f', '#c3c55d', '#b2c25b', '#a3bd5a', '#93b958', '#84b457', '#76af56', '#67a956', '#5aa355', '#4f9e53', '#479751', '#40914f', '#3a8a4d', '#34844a', '#2d7d45', '#257740', '#1c713b', '#146c36'].reverse();
    chartColors: any = ['#e41a1c', '#377eb8', '#4daf4a', '#984ea3', '#ff7f00', '#ffff33', '#a65628', '#f781bf', '#999999'];
    chartData: any = [];
    chartDatasets: any = [];
    chartSetup: any = {
      type: 'line',
      data: {
          datasets: [ ]
      },
      options: {
        elements: { point: { pointRadius: 2 } },
        scales: { x: { 
          type: 'time', 
          time: { unit: 'minute', tooltipFormat: this.chartDateFormat, 
                  displayFormats: { minute: this.chartDateFormat } 
            },
            title: {
              display: true,
              text: 'Time Stamp'
            },
            ticks: {
              source: 'auto',
              maxRotation: 0,
              autoSkip: true
            }
          }
        },
        plugins: {
          legend: {
            position: 'bottom'
          }
        },
        responsive: true,
        animation: false,
        maintainAspectRatio: false
      }
    }
    decimation: any = {
      enabled: false,
      algorithm: 'min-max',
      samples: 100 
    }
    historicChartData: any = [];
    historicChartDatasets: any = [];
    historicChartSetup: any = {
      type: 'line',
      data: {
          datasets: [ ]
      },
      options: {
        parsing: false,
        elements: {
          point: {
            pointRadius: 2
          }
        },
        plugins: {
          legend: {
            position: 'bottom'
          },
          zoom: {
            zoom: {
              wheel: {
                enabled: true,
              },
              drag: {
                enabled: true
              },
              mode: 'x',
            }
          }
        },
        scales: {
          x: {
            type: 'time',
            time: {
              unit: 'minute',
              tooltipFormat: this.chartDateFormat,
              displayFormats: {
                minute: this.chartDateFormat
              }
            },
            title: {
              display: true,
              text: 'Time Stamp'
            },
            ticks: {
              source: 'auto',
              autoSkip: true
            }
          }
        },
        responsive: true,
        animation: false,
        maintainAspectRatio: false,
        zone: this.timeZone
      }
    }
    emailListToEdit = [];
    newEmailAddr: string = null;
    alarmToUpdate: any = null;
    liveLineChart: Chart = null;
    historicLineChart: Chart = null;

    static readonly $inject = [
      'SiteResource',
      'ScheduleResource',
      'FillResource',
      'SupplierResource',
      '$sce',
      'uiGridConstants',
      'SystemResource',
      '$window',
      '$q', '$cookies', 'ReportResource'
    ];

    constructor(
      public SiteResource: interfaces.ISiteResource,
      public ScheduleResource: interfaces.IScheduleResource,
      public FillResource: interfaces.IFillResource,
      public SupplierResource: interfaces.ISupplierResource,
      public $sce: ng.ISCEService,
      public uiGridConstants: any,
      public SystemResource: interfaces.ISystemResource,
      public $window: ng.IWindowService,
      public $q: ng.IQService,
      public $cookies: any,
      public ReportResource: interfaces.IReportResource ) {
        super(SystemResource, $window, $q, $cookies, ReportResource);

      let self = this;
      let href = self.$window.location.pathname.split('/');

      self.requestedId = href[href.length - 1];
      //get All site setup
      self.getSiteData();
      self.getData();

      angular.element(function () {
        self.setActiveTab(self.$cookies.get('siteTab') || 'activeAlarm', self.$cookies.get('sitehref') || '#alarm-attr');
      });

      const ctx = document.getElementById('liveChart') as HTMLCanvasElement;
      const canvas = ctx.getContext('2d');
      this.liveLineChart = new Chart(canvas, this.chartSetup);

      const hctx = document.getElementById('historicChart') as HTMLCanvasElement;
      const hcanvas = hctx.getContext('2d');
      this.historicLineChart = new Chart(hcanvas, this.historicChartSetup);

      self.gridOptions['activeAlarm'] = {
        onRegisterApi: function(gridApi){
          self.gridApi['activeAlarm'] = gridApi;
        },
        enableFiltering: true,
        enableRowHashing:false,
        enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
        rowHeight: this.gridRowHeight,
        data: [],
        columnDefs :[
          { name: 'Unit', field: 'UnitCode', width: '238', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: '<div class="ui-grid-cell-contents">{{ grid.appScope.ctrl.getUnitName(COL_FIELD) }}</div>'
          },
          { name: 'Settings', field: 'Notifications', width: '92', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents"><div class="grid grid-cols-3 inline">
            <div><img class="self-center email-icon filter-white origin-center" ng-show="row.entity.Notifications" title="Notifications Enabled"></i></div>
            <div><iimg class="self-center attention-icon filter-white origin-center" ng-show="row.entity.Critical" title="Alarm is Critical"></i></div>
            <div>
              <img class="self-center alarm-icon filter-white origin-center" ng-show="!row.entity.Disabled" title="Alarm Enabled"></img>
              <img class="self-center no-alarm-icon filter-white origin-center" ng-show="row.entity.Disabled" title="Alarm Disabled"></img>
            </div>
            </div></div>`
          },
          { name: 'Alarm', field: 'Desc.AlarmDesc', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
          { name: 'Active Time', field: 'ActiveTime', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
          },
          { name: 'Recipients', field: 'NotificationList', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: '<div class="ui-grid-cell-contents">{{ grid.appScope.ctrl.getEmailList(COL_FIELD) }}</div>'
          },
          { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents m-0">
              <button class="w-full w-full m-0", type="button", id="alarmDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                <img class="hamburger-vertical-icon"><img>
              </button>
              <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="alarmDropdownMenuButton">
                <li>
                  <button class="dropdown-item" ng-if="grid.appScope.ctrl.siteData.edit" ng-click="grid.appScope.ctrl.setAlarmNotification(row.entity)">{{row.entity.Notifications ? 'Disable':'Enable'}} Notifications</button>
                </li>
                <li>
                  <button class="dropdown-item" ng-if="grid.appScope.ctrl.siteData.edit"  ng-click="grid.appScope.ctrl.setAlarmCritical(row.entity)">Set Alarm {{row.entity.Critical ? 'Normal':'Critical'}}</button>
                </li>
                <li>
                  <button class="dropdown-item" ng-if="grid.appScope.ctrl.siteData.edit"  ng-click="grid.appScope.ctrl.setAlarmDisable(row.entity)">{{row.entity.Disable ? 'Enable':'Disable'}} Alarm</button>
                </li>
                <li>
                  <button class="dropdown-item" ng-if="grid.appScope.ctrl.siteData.edit"  ng-click="grid.appScope.ctrl.editEmailList(row.entity)">Edit Email List</button>
                </li>
              </ul></div>`
          }
        ]
      }

      self.gridOptions['alarm'] = {
        onRegisterApi: function(gridApi){
          self.gridApi['alarm'] = gridApi;
        },
        enableFiltering: true,
        enableRowHashing:false,
        enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
        rowHeight: this.gridRowHeight,
        data: [],
        columnDefs :[
          { name: 'Unit', field: 'UnitCode', width: '238', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: '<div class="ui-grid-cell-contents">{{ grid.appScope.ctrl.getUnitName(COL_FIELD) }}</div>'
          },
          { name: 'Settings', field: 'Notifications', width: '92', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents"><div class="grid grid-cols-3 inline">
              <div><img class="self-center email-icon filter-white origin-center" ng-show="row.entity.Notifications" title="Notifications Enabled"></i></div>
              <div><iimg class="self-center attention-icon filter-white origin-center" ng-show="row.entity.Critical" title="Alarm is Critical"></i></div>
              <div>
                <img class="self-center alarm-icon filter-white origin-center" ng-show="!row.entity.Disabled" title="Alarm Enabled"></img>
                <img class="self-center no-alarm-icon filter-white origin-center" ng-show="row.entity.Disabled" title="Alarm Disabled"></img>
              </div>
            </div></div>`
          },
          { name: 'Alarm', field: 'Desc.AlarmDesc' },
          { name: 'Active Time', field: 'ActiveTime', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
          },
          { name: 'Inactive Time', field: 'InactiveTime', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
          },
          { name: 'Recipients', field: 'NotificationList', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: '<div class="ui-grid-cell-contents">{{ grid.appScope.ctrl.getEmailList(COL_FIELD) }}</div>'
          },
          { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents m-0">
            <button class="w-full w-full m-0", type="button", id="alarmDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
              <img class="hamburger-vertical-icon"><img>
            </button>
            <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="alarmDropdownMenuButton">
              <li>
                <button class="dropdown-item" ng-if="grid.appScope.ctrl.siteData.edit" ng-click="grid.appScope.ctrl.setAlarmNotification(row.entity)">{{row.entity.Notifications ? 'Disable':'Enable'}} Notifications</button>
              </li>
              <li>
                <button class="dropdown-item" ng-if="grid.appScope.ctrl.siteData.edit"  ng-click="grid.appScope.ctrl.setAlarmCritical(row.entity)">Set Alarm {{row.entity.Critical ? 'Normal':'Critical'}}</button>
              </li>
              <li>
                <button class="dropdown-item" ng-if="grid.appScope.ctrl.siteData.edit"  ng-click="grid.appScope.ctrl.setAlarmDisable(row.entity)">{{row.entity.Disable ? 'Enable':'Disable'}} Alarm</button>
              </li>
              <li>
                <button class="dropdown-item" ng-if="grid.appScope.ctrl.siteData.edit"  ng-click="grid.appScope.ctrl.editEmailList(row.entity)">Edit Email List</button>
              </li>
            </ul></div>`
          }
        ]
      }
      
      self.gridOptions['alarmHistory'] = {
        onRegisterApi: function(gridApi){
          self.gridApi['alarmHistory'] = gridApi;
        },
        enableFiltering: true,
        enableRowHashing:false,
        enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
        rowHeight: this.gridRowHeight,
        data: [],
        columnDefs :[
          { name: 'Unit', field: 'UnitCode', width: '238',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ grid.appScope.ctrl.getUnitName(COL_FIELD) }}</div>',
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: []
            }
          },
          { name: 'TimeStamp', maxWidth: '208', field: 'TimeStamp', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
          },
          { name: 'Alarm Id', field: 'AlarmId', width: '92', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
          { name: 'Alarm', field: 'Desc.AlarmDesc', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
          { name: 'Status', field: 'Value', width: '118',
            cellTemplate: `<div class="ui-grid-cell-contents">
              <span class="badge border-white w-full align-middle border-1" ng-class="grid.appScope.ctrl.alarmBadge(COL_FIELD)">{{ COL_FIELD ? "ACTIVE" : "INACTIVE" }}</span>
              </div>`,
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [ { value: true, label: 'Active' }, { value: false, label: 'Inactive'} ]
            }
          },
          { name: 'Notified', field: 'Notified', width: '92',
            cellTemplate: `<div class="ui-grid-cell-contents">
              <img class="circle-check-icon origin-center pr-1" ng-show="row.entity.Notified" title="Notified"></img>
            </div>`,
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [ { value: true, label: 'Notified' }, { value: false, label: 'Not Notified'} ]
            }
          }
        ]
      }

      self.gridOptions['event'] = {
        onRegisterApi: function(gridApi){
          self.gridApi['event'] = gridApi;
        },
        enableFiltering: true,
        enableRowHashing:false,
        enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
        rowHeight: this.gridRowHeight,
        data: [],
        columnDefs :[
          { name: 'Start Time', field: 'StartTime', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
          },
          { name: 'Stop Time', field: 'StopTime', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
          },
          { name: 'Start Status', field: 'StartStatus', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: `<div class="ui-grid-cell-contents">
              <div class="grid grid-cols-2" ng-repeat="unit in row.entity.StartStatusJSON">
                <div>{{ unit.Unit }}</div>
                <div>{{ unit.Op }}</div>
              </div>
            </div>`
          },
          { name: 'Stop Status', field: 'StopStatus', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."},
            cellTemplate: `<div class="ui-grid-cell-contents">
              <div class="grid grid-cols-2" ng-repeat="unit in row.entity.StopStatusJSON">
                <div>{{ unit.Unit }}</div>
                <div>{{ unit.Op }}</div>
              </div>
            </div>`
          },
          { name: 'Created By', field: 'User.LoginName', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
          { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
          cellTemplate: `<div class="ui-grid-cell-contents m-0">
            <button class="w-full w-full m-0", type="button", id="eventDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
              <img class="hamburger-vertical-icon"><img>
            </button>
            <button class="btn  w-full m-0 leading-3 dropdown-toggle", type="button", id="eventDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">Options</button>
            <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="eventDropdownMenuButton">
              <li>
                <button class="dropdown-item", ng-disabled="row.entity.Started && row.entity.Stopped", ng-if="grid.appScope.ctrl.siteData.edit" ng-click="grid.appScope.ctrl.openEditEventModal(row.entity)">Edit</button>
              </li>
            </ul></div>`
          }
        ]
      }

      self.gridOptions['sched'] = {
        onRegisterApi: function(gridApi){
          self.gridApi['sched'] = gridApi;
        },
        enableFiltering: true,
        enableRowHashing:false,
        enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
        rowHeight: this.gridRowHeight,
        data: [],
        columnDefs :[
          { name: 'Start', field: 'StartTime',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
          },
          { name: 'Stop', field: 'StopTime',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
          },
          { name: 'Start Cmd', field: 'StartCmd', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
          { name: 'Stop Cmd', field: 'StopCmd', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
          { name: 'Started', field: 'Started', width: '92',
            cellTemplate: `<div class="ui-grid-cell-contents">
              <img class="circle-cross-icon origin-center pr-1" ng-show="!row.entity.Started" title="Did not start")
              <img class="circle-check-icon origin-center pr-1" ng-show="row.entity.Started" title="Started")
            </div>`,
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [ { value: true, label: 'Started' }, { value: false, label: 'Did Not Start'} ]
            }
          },
          { name: 'Stopped', field: 'Stopped', width: '92',
            cellTemplate: `<div class="ui-grid-cell-contents">
              <img class="circle-cross-icon origin-center pr-1" ng-show="!row.entity.Stopped" title="Did not stop")
              <img class="circle-check-icon origin-center pr-1" ng-show="row.entity.Stopped" title="Stopped")
            </div>`,
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [ { value: true, label: 'Stopped' }, { value: false, label: 'Did Not Stop'} ]
            }
          },
          { name: 'Created By', field: 'ScheduleCreator.Name', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
          { name: 'Group', field: 'CommandGroup.Name', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."} },
          { name: 'Disabled', field: 'Disabled', width: '92',
            cellTemplate: `<div class="ui-grid-cell-contents">
              <img class="circle-cross-icon origin-center pr-1" ng-show="row.entity.Disabled" title="Disabled"></img>
            </div>`,
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [ { value: true, label: 'Disabled' }, { value: false, label: 'Enabled'} ]
            }
          },
          { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
          cellTemplate: `<div class="ui-grid-cell-contents m-0">
            <button class="w-full w-full m-0", type="button", id="schedDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
              <img class="hamburger-vertical-icon"><img>
            </button>
            <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="schedDropdownMenuButton">
              <li>
                <button class="dropdown-item" ng-if="grid.appScope.ctrl.siteData.edit" ng-click="grid.appScope.ctrl.openEditSchedModal(row.entity)">Edit</button>
              </li>
            </ul></div>`
          }
        ]
      }

      self.gridOptions['fill'] = {
        onRegisterApi: function(gridApi){
          self.gridApi['fill'] = gridApi;
        },
        enableFiltering: true,
        enableRowHashing:false,
        enableHorizontalScrollbar: this.uiGridConstants.scrollbars.WHEN_NEEDED,
        rowHeight: this.grid2RowHeight,
        data: [],
        columnDefs :[
          { name: 'OrderDate', field: 'OrderDate', displayName: 'Order Date', width: '136',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateOnlyFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
          },
          { name: 'ScheduleDate', field: 'ScheduleDate', displayName: 'Scheduled Date', width: '146',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ row.entity.On ? "On: " : "By: " }} {{ COL_FIELD | date:grid.appScope.ctrl.dateOnlyFilterFormat:grid.appScope.ctrl.timeZone }}</div>'
          },
          { name: 'FillDate', field: 'FillDate', displayName: 'Fill Date', width: '136',
            cellTemplate: '<div class="ui-grid-cell-contents">{{ COL_FIELD | date:grid.appScope.ctrl.dateOnlyFilterFormat:grid.appScope.ctrl.timeZone }}</div>',
            filter: {
              placeholder: '...Filter Fuel Orders',
              type: uiGridConstants.filter.SELECT,
              selectOptions: [ { value: null , label: 'Open Order' }, { value: true, label: 'No Open Order'} ]
            }
          },
          { name: 'GalDIESEL', field: 'OrderedGalDIESEL', displayName: 'Diesel', width: '96',
            cellTemplate: `<div class="ui-grid-cell-contents">
            {{ (row.entity.FillGalDIESEL ? grid.appScope.ctrl.volumeConvert(row.entity.FillGalDIESEL) : '-') | formatValue:0 }} / {{ grid.appScope.ctrl.volumeConvert(row.entity.OrderedGalDIESEL) | formatValue:0 }} {{grid.appScope.ctrl.units === 'imperial' ? 'gal' : 'L'}}</div>` },
          { name: 'GalDEF', field: 'OrderedGalDEF', displayName: 'DEF', width: '96',
            cellTemplate: `<div class="ui-grid-cell-contents">
            {{ (row.entity.FillGalDEF ? grid.appScope.ctrl.volumeConvert(row.entity.FillGalDEF) : '-') | formatValue:0 }} / {{ grid.appScope.ctrl.volumeConvert(row.entity.OrderedGalDEF) | formatValue:0 }} {{grid.appScope.ctrl.units === 'imperial' ? 'gal' : 'L'}}</div>` },
          { name: 'UnitFills', field: 'UnitsJSON', displayName: 'Unit Fills (Unit, Run Hours, Diesel, DEF)', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents overflow-y-auto">
              <div class="flex flex-row" ng-repeat="unit in COL_FIELD">
                <div class="w-16 flex ui-grid-cell-table-th px-2">
                  {{ unit.UnitId }}
                </div>
                <div class="flex-grow ui-grid-cell-table-td ">
                  {{ (unit.RunHours.Fill ? (unit.RunHours.Fill | formatValue:1) : '-') }} / {{ unit.RunHours.Ordered | formatValue:1 }} hrs
                </div>
                <div class="flex-grow flex justify-between">
                  <div class="ui-grid-cell-table-th">Diesel:</div>
                  <div class="ui-grid-cell-table-td">{{ (unit.FuelLevel.Fill ? (unit.FuelLevel.Fill | formatValue:1) : '-') }} / {{ unit.FuelLevel.Ordered | formatValue:1 }} %</div>
                </div>
                <div class="flex-grow ui-grid-cell-table-td">
                  {{ unit.GalFilled.DIESEL ?  (grid.appScope.ctrl.volumeConvert(unit.GalFilled.DIESEL) | formatValue:0) : '-' }} {{grid.appScope.ctrl.units === 'imperial' ? 'gal' : 'L'}}
                </div>
                <div class="flex-grow justify-between">
                  <div class="ui-grid-cell-table-th">DEF</div>
                  <div class="ui-grid-cell-table-td">{{ (unit.DEFLevel.Fill ? (unit.DEFLevel.Fill | formatValue:1) : '-') }} / {{ unit.DEFLevel.Ordered | formatValue:1 }} %</div>
                </div>
                <div class="flex-grow ui-grid-cell-table-td">
                  {{ unit.GalFilled.DEF ?  (grid.appScope.ctrl.volumeConvert(unit.GalFilled.DEF) | formatValue:0) : '-' }} {{grid.appScope.ctrl.units === 'imperial' ? 'gal' : 'L'}}
                </div>
              </div>
            </div>` 
          },
          { name: 'Created By', field: 'Creator', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."}, visible: false,
            cellTemplate: `<div class="ui-grid-cell-contents">
              {{ COL_FIELD.FirstName }} {{ COL_FIELD.LastName }}<br>({{ COL_FIELD.LoginName }})
              </div>` 
          },
          { name: 'Updated By', field: 'Updater.FirstName', filter: { condition: this.notableCommaFilter, placeholder: "Comma separated..."}, visible: false,
            cellTemplate: `<div class="ui-grid-cell-contents">
              {{ COL_FIELD.FirstName }} {{ COL_FIELD.LastName }}<br>({{ COL_FIELD.LoginName }})
              </div>` 
          },
          { name: 'Cancelled', field: 'Cancelled', width: '92', visible: false,
            cellTemplate: `<div class="ui-grid-cell-contents">
              <img class="circle-cross-icon origin-center pr-1" ng-show="row.entity.Cancelled" title="Cancelled"></img>
            </div>`,
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [ { value: true, label: 'Cancelled' }, { value: false, label: 'Active'} ]
            }
          },
          { name: 'Deleted', field: 'Deleted', width: '92', visible: false,
            cellTemplate: `<div class="ui-grid-cell-contents">
              <img class="circle-cross-icon origin-center pr-1" ng-show="row.entity.Deleted" title="Deleted"></img>
            </div>`,
            filter: {
              term: null,
              type: uiGridConstants.filter.SELECT,
              selectOptions: [ { value: true, label: 'Deleted' }, { value: false, label: 'Active'} ]
            }
          },
          { name: ' ', width: '32', enableFiltering: false, enableSorting: false,
            cellTemplate: `<div class="ui-grid-cell-contents m-0">
              <button class="w-full w-full m-0", type="button", id="fillDropdownMenuButton", data-bs-toggle="dropdown", aria-expanded="false">
                <img class="hamburger-vertical-icon"><img>
              </button>
              <ul class="dropdown-menu dropdown-menu-end", aria-labelledby="fillDropdownMenuButton">
                <li>
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.openEditFill(row.entity)">Edit Fill</button>
                </li>
                <li ng-if="!row.entity.FillDate">
                  <button class="dropdown-item" ng-click="grid.appScope.ctrl.openDeliverFill(row.entity)">Add Delivery</button>
                </li>
              </ul></div>`
          }
        ]
      }

      setInterval(() => { self.getData() }, 5000);
      setInterval(() => { self.getLiveChartHistory() }, 30000);
    }

    showFillDetail = () => {
      this.gridOptions['fill'].columnDefs[5].visible = false;
      this.gridOptions['fill'].columnDefs[6].visible = true;
      this.gridOptions['fill'].columnDefs[7].visible = true;
      this.gridOptions['fill'].columnDefs[8].visible = true;
      this.gridOptions['fill'].columnDefs[9].visible = true;
      this.showFillDetails = true;
      this.showUnitDetails = false;
      this.gridApi['fill'].grid.refresh();
    }

    showUnitDetail = () => {
      this.gridOptions['fill'].columnDefs[5].visible = true;
      this.gridOptions['fill'].columnDefs[6].visible = false;
      this.gridOptions['fill'].columnDefs[7].visible = false;
      this.gridOptions['fill'].columnDefs[8].visible = false;
      this.gridOptions['fill'].columnDefs[9].visible = false;
      this.showFillDetails = false;
      this.showUnitDetails = true;
      this.gridApi['fill'].grid.refresh();
    }

    openChartModal = () => {
      this.historyDates = { StartTime: moment().second(0).subtract(1, 'days'), EndTime: moment().second(0), Now: moment().second(0) };
      this.pauseLiveChart = true;
      $("#chartHistoryModal").modal("show");
    }

    closeChartModal = () => {
      this.pauseLiveChart = false;
      $("#chartHistoryModal").modal("hide");
    }

    setActiveTab = (tab, hr) => {
      this.$cookies.put('siteTab', tab);
      this.$cookies.put('sitehref', hr);
      this.activeTab = tab;
      $(`[href="${hr}"]`).tab('show');
      if (this.gridApi.hasOwnProperty(tab)) {
        this.gridApi[tab].core.handleWindowResize();
      }
      if (tab === 'chart') {
        this.getLiveChartHistory();
      }
    }

    setupChartArrays = () => {
      this.chartDatasets = [];
      this.chartData = [];
      this.chartSetup.options.scales = {
        x: {
          type: 'time',
          time: {
            unit: 'minute',
            tooltipFormat: this.chartSecondFormat,
            displayFormats: {
              minute: this.chartDateFormat
            }
          },
          title: {
            display: true,
            text: 'Time Stamp'
          },
          ticks: {
            source: 'auto',
            autoSkip: true
          }
        // },
        // brk: {
        //   offset: true,
        //   title: {
        //     display: true,
        //     text: 'Breaker'
        //   },
        //   min: 0,
        //   max: 1,
        //   ticks: {
        //     stepSize: 1
        //   },
        //   position: 'left',
        //   stacked: false,
        //   stack: 'brk',
        //   stackWeight: 1,
        //   beginAtZero: true
        }
      };
      for (const type in this.siteData.iodata.tags) {
        for (const unit in this.siteData.iodata.tags[type]) {
          if (typeof this.siteData.iodata.tags[type][unit] === 'object') {
            // this.setupChartVar(this.chartSetup, this.chartDatasets, this.chartData, type, unit, 'brk', 'left');
            this.setupChartVar(this.chartSetup, this.chartDatasets, this.chartData, type, unit, this.selectedVar, 'left');
            if (this.selectedVar2) {
              this.setupChartVar(this.chartSetup, this.chartDatasets, this.chartData, type, unit, this.selectedVar2, 'right');
            }
          }
        }
      }
      this.liveLineChart.data.datasets = this.chartDatasets;
    }

    setupHistoricChartArrays = () => {
      this.historicChartDatasets = [];
      this.historicChartData = [];
      this.historicChartSetup.options.scales = {
        x: {
          type: 'time',
          time: {
            unit: 'minute',
            tooltipFormat: this.chartSecondFormat,
            displayFormats: {
              minute: this.chartDateFormat
            }
          },
          title: {
            display: true,
            text: 'Time Stamp'
          },
          ticks: {
            source: 'auto',
            autoSkip: true
          }
        }
      };
      for (const type in this.siteData.iodata.tags) {
        for (const unit in this.siteData.iodata.tags[type]) {
          if (typeof this.siteData.iodata.tags[type][unit] === 'object') {
            this.setupChartVar(this.historicChartSetup, this.historicChartDatasets, this.historicChartData, type, unit, this.selectedHVar, 'left');
            if (this.selectedHVar2) {
              this.setupChartVar(this.historicChartSetup, this.historicChartDatasets, this.historicChartData, type, unit, this.selectedHVar2, 'right');
            }
          }
        }
      }
      this.historicLineChart.data.datasets = this.historicChartDatasets;
    }

    setupChartVar = (chartSetup, chartDatasets, chartData, type, unit, selectedVar, position) => {
      if (this.siteData.iodata.tags[type][unit].hasOwnProperty(selectedVar)) {
        chartDatasets.push({ 
          label: this.siteData.iodata.tags[type][unit]['name'] + ' [' + (this.varRefs ? this.varRefs[selectedVar].desc : selectedVar) + ']',
          data: chartData,
          parsing: {
            yAxisKey: unit + '-' + selectedVar
          },
          fill: false,
          borderColor: this.chartColors[chartDatasets.length % this.chartColors.length],
          spanGaps: true,
          stepped: false,
          yAxisID: selectedVar
        });
        chartSetup.options.scales[selectedVar] = {
          position: position,
          title: {
            display: true,
            text: this.varRefs ? this.varRefs[selectedVar].unit : selectedVar
          },
          beginAtZero: true
        }
      }
    }

    setLiveVariables = () => {
      this.getLiveChartHistory();
      $("#chartFilterDropdown").dropdown("toggle");
    }

    getLiveChartHistory = () => {
      if(this.activeTab === 'chart' && !this.pauseLiveChart) {
        this.updatingChart = true;
        this.SiteResource.getRawData({ id: this.requestedId, startTime: luxon.DateTime.now().minus({ minutes: 70 }).toISO(), endTime: luxon.DateTime.now().toISO() }).$promise.then(results => {
          if (results && results.length > 0) {
            // Setup what units are available from the iodata.tags
            this.setupChartArrays();
            results.forEach(record => {
              let data = JSON.parse(record.Data);
              let tzDate = luxon.DateTime.fromISO(record.TimeStamp).setZone(this.timeZone);
              let newDataRec = { x: tzDate };

              for (const type in data) {
                for (const unit in data[type]) {
                  if (data[type][unit].hasOwnProperty(this.selectedVar)) {
                    if (typeof data[type][unit] === 'object') {
                      newDataRec[unit + '-' + this.selectedVar] = data[type][unit][this.selectedVar];
                    }
                  }
                  if (this.selectedVar2) {
                    if (data[type][unit].hasOwnProperty(this.selectedVar2)) {
                      if (typeof data[type][unit] === 'object') {
                        newDataRec[unit + '-' + this.selectedVar2] = data[type][unit][this.selectedVar2];
                      }
                    }
                  }
                }
              }
              this.chartData.push(newDataRec);
            });
            this.liveLineChart.update();
          }
          this.updatingChart = false;
        });
      }
    }

    getVarHistory = () => {
      if(this.historyDates.EndTime.diff(this.historyDates.StartTime, 'days') > 7) {
        toastr.error('Date range is too large.  Maximum of 7 days.');
      } else {
        let startTime = this.historyDates.StartTime.second(0).toISOString();
        let endTime = this.historyDates.EndTime.second(0).toISOString();
        this.SiteResource.getRawData({ id: this.requestedId, startTime: startTime, endTime: endTime }).$promise.then(results => {
          if (results && results.length > 0) {
            // Setup what units are available from the iodata.tags
            this.setupHistoricChartArrays();
            let day = null;
            results.forEach(record => {
              this.pushVarHistory(record);
            });
            if (this.historicChartData.length > 500) {
              this.decimation.enabled = true;
              this.decimation.algorithm = 'lttb';
              this.decimation.samples = 10;
            }
            this.historicLineChart.update();
          }
        });
      }
    }

    pushVarHistory = (record) => {
      let data = JSON.parse(record.Data);
      let tzDate = luxon.DateTime.fromISO(record.TimeStamp).setZone(this.timeZone);
      let newDataRec = { x: tzDate };
      for (const type in data) {
        for (const unit in data[type]) {
          if (data[type][unit].hasOwnProperty(this.selectedVar)) {
            if (typeof data[type][unit] === 'object') {
              newDataRec[unit + '-' + this.selectedVar] = data[type][unit][this.selectedVar];
            }
          }
          if (this.selectedVar2) {
            if (data[type][unit].hasOwnProperty(this.selectedVar2)) {
              if (typeof data[type][unit] === 'object') {
                newDataRec[unit + '-' + this.selectedVar2] = data[type][unit][this.selectedVar2];
              }
            }
          }
        }
        this.historicChartData.push(newDataRec);
      }
    }

    getData = () => {
      this.busy = true;
      let selectPromises = [];
      selectPromises.push(this.SiteResource.get({ id: this.requestedId }).$promise);
      selectPromises.push(this.ScheduleResource.findAllSite({ id: this.requestedId }).$promise);
      this.$q.all(selectPromises).then((results: any[][]) => {
        if (results[0]) {
          this.siteData = results[0];
          for (const type in this.siteData.iodata.tags) {
            for (const unit in this.siteData.iodata.tags[type]) {
              if (typeof this.siteData.iodata.tags[type][unit] === 'object') {
                let found = this.availableUnits.find((o, i) => {
                  if (o.value === unit) {
                      return true;
                  }
                });
                if(!found) {
                  this.availableUnits.push({ label: this.siteData.iodata.tags[type][unit]['name'], value: unit});
                  this.gridOptions['alarmHistory'].columnDefs[0].filter.selectOptions.push({ label: this.siteData.iodata.tags[type][unit]['name'] , value: unit});
                }
              }
            }
          }
          for (let Event of this.siteData.Events) {
            if (Event.StartStatus) {
              if(this.isJSON(Event.StartStatus)) {
                Event['StartStatusJSON'] = JSON.parse(Event.StartStatus);
              } else {
                Event['StartStatusJSON'] = Event.StartStatus;
              }
            }
            if (Event.StopStatus) {
              if(this.isJSON(Event.StopStatus)) {
                Event['StopStatusJSON'] = JSON.parse(Event.StopStatus);
              } else {
                Event['StopStatusJSON'] = Event.StopStatus;
              }
              
            }
          }
          this.gridOptions['activeAlarm'].data = this.siteData.Alarms.filter((alm) => {
            return alm.Value;
          });
          this.gridOptions['alarm'].data = this.siteData.Alarms;
          this.gridOptions['event'].data = this.siteData.Events;
          this.gridOptions['fill'].data = this.siteData.Fills;
          this.locationString = 'https://www.google.com/maps/embed/v1/place?q=';
          let addressString = this.siteData.Address1 + ',';
          addressString += this.siteData.Address2 ? this.siteData.Address2 + ',' : '';
          addressString += this.siteData.Address3 ? this.siteData.Address3 + ',' : '';
          addressString += this.siteData.Address4 ? this.siteData.Address4 + ',' : '';
          addressString += this.siteData.Locality + ',';
          addressString += this.siteData.Region + ',';
          addressString += this.siteData.Postcode;

          if (this.siteData.iodata.hasOwnProperty('location')) {
            if (this.siteData.iodata.location.valid === "true") {
              // Get lat/lng here instead.
              addressString = this.siteData.iodata.location.lat + ',';
              addressString += this.siteData.iodata.location.long;
            }
          }
          this.locationString += addressString.replace(/ /g, '+');
          this.locationString += '&key=AIzaSyD_aa-CyGznqRYjaZ8wGLVD80KNuv5dLAI&maptype=satellite';
        }
        if (results[1]) {
          this.gridOptions['sched'].data = results[1];
        }
        this.busy = false;
      });
    }

    getMapUrl = () => {
      return this.$sce.trustAsResourceUrl(this.locationString);
    }

    sendCommand = (unit: string, cmd: string) => {
      let SiteId = this.siteData.id;
      let newCommand = {
        SiteId: SiteId,
        UnitCode: unit,
        Cmd: cmd
      }
      this.SiteResource.sendCommand(newCommand).$promise.then(result => {
        if (result.success) {
          toastr.success("Command sent.");
        } else {
          toastr.error("Failed to send command: " + result.message);
        }
      });
    }

    brkBadge = (brk) => {
      let result = 'bg-gs-danger-fade';
      if (brk === undefined || brk === null) {
        result = 'bg-gs-unknown';
      }
      if (brk === "OFF" || brk === false || brk === "FALSE" || brk === 0) {
        result = 'bg-gs-success-fade';
      }
      return result;
    }

    utilBadge = (status) => {
      let result = 'bg-gs-success-fade';
      if (status === undefined || status === null) {
        result = 'bg-gs-unknown';
      }
      if (status?.indexOf('OFF') > -1) {
        result = 'bg-gs-danger-fade';
      }
      return result;
    }

    genBadge = (status) => {
      let result = 'bg-gs-danger-fade';
      if (status === undefined || status === null) {
        result = 'bg-gs-unknown';
      }
      if (status === "ON") {
        result = 'bg-gs-success-fade';
      }
      return result;
    }

    alarmBadge = (status) => {
      let result = 'bg-gs-success-fade';
      if (status === undefined || status === null) {
        result = 'bg-gs-unknown';
      }
      if (status) {
        result = 'bg-gs-danger-fade';
      }
      return result;
    }

    getComms = (comms) => {
      return (comms & 3) != 1;
    }

    getUnitName = (unitcode) => {
      let unitsplit = unitcode.split('_');
      let type = unitsplit[0];
      return this.siteData.iodata.tags[type][unitcode]['name'];
    }

    getEmailList = (notificationString) => {
      let emailList = "";
      if (notificationString) {
        let emailListJSON = JSON.parse(notificationString);
        emailList = emailListJSON.join(', ');
      }
      return emailList;
    }

    editEmailList = (alm) => {
      this.alarmToUpdate = JSON.parse(JSON.stringify(alm));
      this.emailListToEdit = [];
      if (this.alarmToUpdate.NotificationList) {
        this.emailListToEdit = JSON.parse(this.alarmToUpdate.NotificationList);
      }
      $("#editEmailModal").modal("show");
    }

    addNewEmailToList = (email) => {
      //check email address
      if (this.validateEmail(email)) {
        if (this.emailListToEdit.indexOf(email) === -1) {
          this.emailListToEdit.push(email);
        }
      } else {
        toastr.error('Not a valid email address');
      }
    }

    setAlarmNotification = (alm) => {
      let almUpdate = JSON.parse(JSON.stringify(alm));
      almUpdate.Notifications = !alm.Notifications;
      this.updateAlarm(almUpdate);
    }

    setAlarmCritical = (alm) => {
      let almUpdate = JSON.parse(JSON.stringify(alm));
      almUpdate.Critical = !alm.Critical;
      this.updateAlarm(almUpdate);
    }

    setAlarmDisable = (alm) => {
      let almUpdate = JSON.parse(JSON.stringify(alm));
      almUpdate.Disabled = !alm.Disabled;
      this.updateAlarm(almUpdate);
    }

    saveNotificationList = () => {
      this.alarmToUpdate.NotificationList = JSON.stringify(this.emailListToEdit);
      this.updateAlarm(this.alarmToUpdate);
    }

    updateAlarm = (alm) => {
      this.SiteResource.updateAlarmNotify(alm).$promise.then((result) => {
        if (result) {
          toastr.success('Updated Alarm Notification Setting');
          this.getData();
          $("#editEmailModal").modal("hide");
        } else {
          toastr.error('Failed to update alarm.');
        }
      });
    }

    getSiteData = () => {
      let selectPromises = [];
      selectPromises.push(this.SiteResource.getSiteOneLine({ id: this.requestedId }).$promise);
      selectPromises.push(this.SiteResource.getAlarmCodes({ id: this.requestedId }).$promise);
      this.$q.all(selectPromises).then((results: any[][]) => {
        if (results[0]['OneLine']) {
          this.oneLineGrid = JSON.parse(results[0]['OneLine']);
        }
        if (results[1]) {
          this.availableAlarms = results[1];
          this.availableAlarms.unshift({ id: null, name: 'All Alarms' });
        }
      });
    }

    openGetHistory = () => {
      this.requestedType = null;
      this.requestedUnitId = null;
      this.historyDates = { StartTime: moment().subtract(1, 'days'), EndTime: moment(), Now: moment() };
      $("#getHistoryModal").modal("show");
    }

    getHistory = () => {
      this.SiteResource.getDataByUnitFlat({
        id: this.requestedId,
        unitId: this.requestedUnitId || 'null',
        startTime: this.historyDates.StartTime.toISOString(),
        endTime: this.historyDates.EndTime.toISOString()
      }).$promise.then(results => {
        this.downloadFile(results.data, this.historyDates.StartTime.format("YYYYMMDDHHmm"));
        $("#getHistoryModal").modal("hide");
      });
    }

    clearAlarmHistory = () => {
      this.alarmHistoryQuery = {
        unitId: null,
        alarmId: null,
        startTime: null,
        endTime: null
      }
    }

    getAlarmHistory = () => {
      this.SiteResource.getAlarmHistory({
        id: this.requestedId,
        alarmId: this.alarmHistoryQuery.alarmId || 'null',
        unitId: this.alarmHistoryQuery.unitId || 'null',
        startTime: this.alarmHistoryQuery.startTime ? moment(this.alarmHistoryQuery.startTime).toISOString() : 'null',
        endTime: this.alarmHistoryQuery.endTime ? moment(this.alarmHistoryQuery.endTime).toISOString() : 'null'
      }).$promise.then((results) => {
        if (results) {
          this.gridOptions['alarmHistory'].data = results;
        }
        $("#historyFilterDropdown").dropdown("toggle");
      });
    }

    getToday = () => {
      this.historyDates = { StartTime: moment().subtract(1, 'days'), EndTime: moment(), Now: moment() };
      this.SiteResource.getDataByUnitFlat({
        id: this.requestedId,
        unitId: 'null',
        startTime: this.historyDates.StartTime.toISOString(),
        endTime: this.historyDates.EndTime.toISOString()
      }).$promise.then(results => {
        this.downloadFile(results.data, this.historyDates.StartTime.format("YYYYMMDDHHmm"));
        $("#getHistoryModal").modal("hide");
      });
    }

    downloadFile = (csvData: string, startTime: string) => {
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') === -1;
      let fileName = this.siteData.Name + ' ' + startTime + '.csv';
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", fileName);
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
      URL.revokeObjectURL(url);
    }

    openCreateFill = () => {
      this.activeEdit = true;
      let selectPromises = [];
      selectPromises.push(this.SupplierResource.list().$promise);
      this.$q.all(selectPromises).then((results: any[][]) => {
        if (results[0]) {
          this.supplierList = results[0];
          this.selectedSupplier = null;
          this.selectedUnits = [];
          this.selectedUnitsJSON = {};
          this.selectedSite =  JSON.parse(JSON.stringify(this.siteData));
          this.selectedFill = {
            id: null,
            SiteId: this.selectedSite.id,
            Units : null,
            SupplierId: null,
            OrderedGalDIESEL: null,
            OrderedGalDEF: null,
            OrderedGalPROP: null,
            FillGalDIESEL: null,
            FillGalDEF: null,
            FillGalPROP: null,
            PriceDIESEL: null,
            PriceDEF: null,
            PricePROP: null,
            TaxDIESEL: null,
            TaxDEF: null,
            TaxPROP: null,
            OrderDate: moment(),
            ScheduleDate: moment().add(1, 'days'),
            On: true,
            FillDate: null,
            Cancelled: false,
            Deleted: false,
            Notes: null,
            OrderedBy: null,
            UpdatedBy: null
          }
          $("#editFillModal").modal("show");
        }
      });
    }

    openEditFill = (fill) => {
      this.activeEdit = true;
      let selectPromises = [];
      selectPromises.push(this.SupplierResource.list().$promise);
      this.$q.all(selectPromises).then((results: any[][]) => {
        if (results[0]) {
          this.supplierList = results[0];
          this.selectedSite = JSON.parse(JSON.stringify(this.siteData));
          this.selectedFill = JSON.parse(JSON.stringify(fill));
          this.selectedUnitsJSON = JSON.parse(this.selectedFill.Units);
          this.selectedSupplier = this.selectedFill.Supplier;
          this.selectedUnits = [];
          this.selectedFill.OrderDate = this.selectedFill.OrderDate ? moment(this.selectedFill.OrderDate) : null;
          this.selectedFill.ScheduleDate = this.selectedFill.ScheduleDate ? moment(this.selectedFill.ScheduleDate) : null;
          this.selectedFill.FillDate = this.selectedFill.FillDate ? moment(this.selectedFill.FillDate) : null;
          for (let unitCode in this.selectedUnitsJSON) {
            this.selectedUnits.push(unitCode);
          }
          $("#editFillModal").modal("show");
        }
      });
    }

    openDeliverFill = (fill) => {
      this.activeEdit = true;
      let selectPromises = [];
      selectPromises.push(this.SupplierResource.list().$promise);
      this.$q.all(selectPromises).then((results: any[][]) => {
        if (results[0]) {
          this.supplierList = results[0];
          this.selectedSite = JSON.parse(JSON.stringify(this.siteData));
          this.selectedFill = JSON.parse(JSON.stringify(fill));
          this.selectedUnitsJSON = JSON.parse(this.selectedFill.Units);
          this.selectedSupplier = this.selectedFill.Supplier;
          this.selectedUnits = [];
          this.selectedFill.OrderDate = this.selectedFill.OrderDate ? moment(this.selectedFill.OrderDate) : null;
          this.selectedFill.ScheduleDate = this.selectedFill.ScheduleDate ? moment(this.selectedFill.ScheduleDate) : null;
          this.selectedFill.FillDate = this.selectedFill.FillDate ? moment(this.selectedFill.FillDate) : null;
          for (let unitCode in this.selectedUnitsJSON) {
            this.selectedUnits.push(unitCode);
          }
          $("#editFillModal").modal("show");
        }
      });
    }

    saveFill = () => {
      this.selectedFill.Units = JSON.stringify(this.selectedUnitsJSON);
      this.selectedFill.OrderDate = this.selectedFill.OrderDate ? moment(this.selectedFill.OrderDate).toDate() : null;
      this.selectedFill.ScheduleDate = this.selectedFill.ScheduleDate ? moment(this.selectedFill.ScheduleDate).toDate() : null;
      this.selectedFill.FillDate = this.selectedFill.FillDate ? moment(this.selectedFill.FillDate).toDate() : null;

      if (this.selectedFill.id) {
        this.updateFill();
      } else {
        this.createFill();
      }
    }

    createFill = () => {
      this.FillResource.create(this.selectedFill).$promise.then(result => {
        if ( result ) {
          toastr.success('Fill created');
          this.selectedFill = null;
          this.activeEdit = false;
          $("#editFillModal").modal("hide");
          this.getData();
        } else {
          toastr.error('Could not create fill');
        }
      });
    }
    
    updateFill = () => {
      this.FillResource.update({ id: this.selectedFill.id }, this.selectedFill).$promise.then(result => {
        if(result.success === true) {
          toastr.success("Saved Fill");
          this.activeEdit = false;
          $("#editFillModal").modal("hide");
        } else {
          toastr.error(result.message);
        }
      });
    }

    setUnits = () => {
      let tempUnits = {};
      this.selectedUnits.forEach(selectedUnit => {
        if(!this.selectedUnitsJSON.hasOwnProperty(selectedUnit)) {
          this.selectedUnitsJSON[selectedUnit] = {
            UnitId: selectedUnit,
            FuelLevel: { Ordered: this.selectedSite.iodata.tags['G'][selectedUnit].dlvl, Fill: null },
            DEFLevel: { Ordered: this.selectedSite.iodata.tags['G'][selectedUnit].deflvl, Fill: null },
            RunHours: { Ordered: this.selectedSite.iodata.tags['G'][selectedUnit].hrs, Fill: null },
            GalFilled: { DIESEL: null, DEF: null, PROP: null }
          }
        }
      });
      for (let unitCode of this.selectedUnits) {
        tempUnits[unitCode] = JSON.parse(JSON.stringify(this.selectedUnitsJSON[unitCode]));
      }
      this.selectedUnitsJSON = JSON.parse(JSON.stringify(tempUnits));
    }
    // {"on":"orange","off":"blue","cond":[[{"type":"M","unit":"M1","var":"open","val":"0","comp":"EQ"}],[...]]}
    getCellDataValue = (val: string, _def?: string) => {
      let linecolor = 'black';
      if (val) {
        try {
          let varObj = JSON.parse(val);
          if (varObj['cond']) {
            let result = false;
            varObj['cond'].forEach((cOr: any) => {
              if (cOr) {
                let resultAnd = true;
                cOr.forEach((cAnd: any) => {
                  let checkValue = null;
                  checkValue = this.siteData.iodata.tags[cAnd['type']][cAnd['unit']][cAnd['var']];
                  switch (cAnd['comp']) {
                    case '=':
                    case 'EQ':
                      if (checkValue != cAnd['val']) {
                        resultAnd = false;
                      }
                      break;
                    case '!=':
                    case 'NE':
                      if (checkValue === cAnd['val']) {
                        resultAnd = false;
                      }
                      break;
                    case '<':
                    case 'LT':
                      if (checkValue >= cAnd['val']) {
                        resultAnd = false;
                      }
                      break;
                    case '>':
                    case 'GT':
                      if (checkValue <= cAnd['val']) {
                        resultAnd = false;
                      }
                      break;
                    case '<=':
                    case 'LE':
                      if (checkValue > cAnd['val']) {
                        resultAnd = false;
                      }
                      break;
                    case '>=':
                    case 'GE':
                      if (checkValue < cAnd['val']) {
                        resultAnd = false;
                      }
                      break;
                    default:
                      break;
                  }
                });
                if (resultAnd) {
                  result = true;
                }
              }
            });
            linecolor = result ? varObj['on'] : varObj['off'];
          }
        } catch (e) {
          linecolor = val;
        }
      }
      return linecolor;
    };

  }
  angular.module('gsapp').controller('SiteController', SiteController);
}